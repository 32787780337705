import * as React from 'react'
import {useSkilQuery} from '../../Utilities/QueryClient'
import DataGrid from '../../Components/DataGrid/DataGrid'
import {GridColDef, GridSelectionModel} from '@mui/x-data-grid-premium'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import dateColumnDef from '../../Components/DataGrid/columnDefs/dateColumnDef'
import Button from '../../Components/Button/Button'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import selectColumnDef from '../../Components/DataGrid/columnDefs/selectColumnDef'
import {SendMessageDialog} from '../components/SendMessageDialog'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import CircularProgress from '@mui/material/CircularProgress'
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import {SpecialActionProps} from '../../Components/DataGrid/SpecialAction'
import SpecialActions from '../../Components/DataGrid/SpecialActions'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import {DoctorsAddToStudyDialog} from '../DetailsViews/Doctor/DoctorsAddToStudyDialog'

const renderCell = params => {
    if (!params.value) return <span>ingen navn</span>

    return (
        <Button variant='text' to={`/dashboard/praksisnett/offices/${params.row.id}`}>
            {params.value}
        </Button>
    )
}
const renderDoctorCell = params => {
    if (!params.value) return <span />

    return (
        <Button variant='text' to={`/dashboard/praksisnett/doctors/${params.row.contact.id}`}>
            {params.value}
        </Button>
    )
}

export const SNOWBOX_TEST_OPTIONS = [
    {label: 'Ok', value: 0},
    {label: 'Invitert', value: 1},
    {label: 'Signert', value: 2},
]
export const SNOWBOX_EXTRACTION_OPTIONS = [
    {label: 'Nei', value: 0},
    {label: 'Ja', value: 1},
    {label: 'Feilet', value: -1},
]
export const SNOWBOX_SKY_OPTIONS = [
    {label: 'Ingen', value: 0},
    {label: 'Snart', value: 1},
    {label: 'Ja', value: 2},
]

const columnVisibilityModel = {
    'county.id': false,
    createdAt: false,
    'snowbox.journal': false,
    'snowbox.deployedAt': false,
    'snowbox.drcInstalled': false,
    'snowbox.cancelledAt': false,
    'snowbox.cancelledReason': false,
    'snowbox.location': false,
    'snowbox.invitationAvailable': false,
    'snowbox.notes': false,
}

const columns: GridColDef[] = [
    columnDef({field: 'id', headerName: 'System-ID', type: 'number', renderCell}),
    columnDef({field: 'name', headerName: 'Navn', type: 'string', renderCell}),
    columnDef({field: 'region', headerName: 'Region', type: 'string'}),
    columnDef({field: 'orgNr', headerName: 'Orgnr.', type: 'number'}),
    columnDef({field: 'address', headerName: 'Adresse', type: 'string'}),
    columnDef({field: 'county.name', headerName: 'Kommune', type: 'string'}),
    columnDef({field: 'contact.name', headerName: 'Kontakt', type: 'string', renderCell: renderDoctorCell}),
    columnDef({field: 'county.id', headerName: 'Kommunenr', type: 'number'}),
    columnDef({field: 'signedContract', headerName: 'På kontrakt', type: 'boolean'}),
    dateColumnDef({field: 'createdAt', headerName: 'Opprettet', type: 'dateTime'}),

    columnDef({field: 'snowbox.online', headerName: 'Snowbox Online', type: 'boolean'}),
    columnDef({field: 'snowbox.status', headerName: 'Snowbox Status', type: 'string'}),
    columnDef({field: 'snowbox.journal', headerName: 'Snowbox Journal', type: 'string'}),
    dateColumnDef({field: 'snowbox.deployedAt', headerName: 'Snowbox Utplassert', type: 'dateTime'}),
    columnDef({field: 'snowbox.drcInstalled', headerName: 'Snowbox DRC Innstallert', type: 'boolean'}),
    selectColumnDef({field: 'snowbox.extraction', headerName: 'Snowbox Uttrekk', valueOptions: SNOWBOX_EXTRACTION_OPTIONS}),
    selectColumnDef({field: 'snowbox.test', headerName: 'Snowbox Test', valueOptions: SNOWBOX_TEST_OPTIONS}),
    selectColumnDef({field: 'snowbox.sky', headerName: 'Snowbox Sky', valueOptions: SNOWBOX_SKY_OPTIONS}),
    dateColumnDef({field: 'snowbox.cancelledAt', headerName: 'Snowbox Avlyst', type: 'dateTime'}),
    columnDef({field: 'snowbox.cancelledReason', headerName: 'Snowbox Avlyst grunn', type: 'string'}),
    columnDef({field: 'snowbox.location', headerName: 'Snowbox Plassering', type: 'string'}),
    columnDef({field: 'snowbox.invitationAvailable', headerName: 'Snowbox Tilgjengelig Invitasjon', type: 'boolean'}),
    columnDef({field: 'snowbox.notes', headerName: 'Snowbox Notater', type: 'string'}),
]
export const Offices = () => {
    const officesResponse = useSkilQuery<'getPraksisNettOffices'>('/api/praksisnett/offices', {pagination: false})
    const [showEmailDialog, setShowEmailDialog] = React.useState(false)
    const [showTextDialog, setShowTextDialog] = React.useState(false)
    const [showAddToStudy, setShowAddToStudy] = React.useState(false)
    const [selectedOffices, setSelectedOffices] = React.useState<GridSelectionModel>([])
    const {data: selectedDoctorsData, isLoading: isLoadingSelectedDoctors} = useSkilQuery<'getPraksisnettDoctorCollection'>(
        '/api/praksisnett/doctors',
        {'office[]': selectedOffices, pagination: false},
        {enabled: selectedOffices.length > 0}
    )

    const officesActions: SpecialActionProps[] = [
        {
            label: 'Send SMS',
            action: () => setShowTextDialog(true),
            disabled: selectedOffices.length === 0,
            icon: {
                position: 'start',
                icon: <PhoneIphoneOutlinedIcon />,
            },
        },
        {
            label: 'Send e-post',
            action: () => setShowEmailDialog(true),
            disabled: selectedOffices.length === 0,
            icon: {
                position: 'start',
                icon: <EmailOutlinedIcon />,
            },
        },
        {
            label: 'Legg til i studie',
            action: () => setShowAddToStudy(true),
            disabled: selectedOffices.length === 0,
            icon: {
                position: 'start',
                icon: <AssessmentOutlinedIcon />,
            },
        },
    ]

    const onChangeSelection = (event, value) => {
        setSelectedOffices(value.map(v => v.id))
    }
    const offices = officesResponse.data?.['hydra:member'] ?? []
    const selectedDoctorIris = selectedOffices.length > 0 ? (selectedDoctorsData?.['hydra:member'] ?? []).map(d => d['@id'] as string) : []
    const currentSelection = offices.filter(i => selectedOffices.includes(Number(i.id)))

    return (
        <>
            <DataGridWrapper rows={offices.length}>
                <DataGrid
                    id={'offices'}
                    loading={officesResponse.isLoading && offices.length === 0}
                    checkboxSelection
                    onSelectionModelChange={newSelectedOffices => {
                        setSelectedOffices(newSelectedOffices)
                    }}
                    selectionModel={selectedOffices}
                    rows={offices}
                    columns={columns}
                    initModel={{columnVisibilityModel}}
                    toolbarButtons={<SpecialActions actions={officesActions} />}
                />
            </DataGridWrapper>
            <SendMessageDialog
                isOpen={showEmailDialog}
                onClose={() => setShowEmailDialog(false)}
                doctorIris={isLoadingSelectedDoctors ? [] : selectedDoctorIris}
                isEmail
            >
                <Autocomplete
                    multiple
                    id='recipients'
                    options={offices}
                    limitTags={5}
                    value={currentSelection}
                    onChange={onChangeSelection}
                    getOptionLabel={option => String(option.name)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label='Mottakere'
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: isLoadingSelectedDoctors ? (
                                    <InputAdornment position='end'>
                                        <CircularProgress size={16} aria-label='Laster leger' />
                                    </InputAdornment>
                                ) : (
                                    params.InputProps.endAdornment
                                ),
                            }}
                        />
                    )}
                />
            </SendMessageDialog>
            <SendMessageDialog
                isOpen={showTextDialog}
                onClose={() => setShowTextDialog(false)}
                doctorIris={isLoadingSelectedDoctors ? [] : selectedDoctorIris}
                isText
            >
                <Autocomplete
                    multiple
                    id='recipients'
                    options={offices}
                    limitTags={5}
                    value={currentSelection}
                    onChange={onChangeSelection}
                    getOptionLabel={option => String(option.name)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label='Mottakere'
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: isLoadingSelectedDoctors ? (
                                    <InputAdornment position='end'>
                                        <CircularProgress size={16} aria-label='Laster leger' />
                                    </InputAdornment>
                                ) : (
                                    params.InputProps.endAdornment
                                ),
                            }}
                        />
                    )}
                />
            </SendMessageDialog>
            <DoctorsAddToStudyDialog
                isOpen={showAddToStudy}
                onClose={() => setShowAddToStudy(false)}
                doctorIris={selectedDoctorIris}
                setSelected={setSelectedOffices}
            />
        </>
    )
}
