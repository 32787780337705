var GridPinnedPosition;
/**
 * The column pinning API interface that is available in the grid [[apiRef]].
 */

(function (GridPinnedPosition) {
  GridPinnedPosition["left"] = "left";
  GridPinnedPosition["right"] = "right";
})(GridPinnedPosition || (GridPinnedPosition = {}));

export { GridPinnedPosition };