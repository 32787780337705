import * as React from 'react'
import Button from '../../Components/Button/Button'
import Collapse from '../../Components/Collapse'
import FindUserModal, {FindUserModalDTO} from '../../Components/FindUserModal'
import FormGroup from '../../Components/FormGroup'
import styled from 'styled-components'
import LoadingComponent from '../../Components/LoadingComponent'
import ErrorComponent from '../../Components/ErrorComponent'
import {useSkilQuery} from '../../Utilities/QueryClient'
import {SetState} from '../../Utilities/TypeHelpers'
import CheckboxControl from '../../Components/Fields/CheckboxControl'
import {toast} from 'react-toastify'
import {DistrictsExport} from '../../Components/DistrictsExport'
import OfficeComponent from './OfficeComponent'

export interface Participation {
    courseInvitation: number
    courseAcknowledged: number
    seminarInvited: number
    seminarAcknowledged: number
}

export interface Employee {
    id: number
    '@id': string
    name: string
    email: string
    isRegistered: boolean
    hasValidEmail: boolean
    hasHelfoConnection: boolean
    isTemp: boolean
    helfoRole: string
    skilRole: string
    participation: Participation
}

export type ParticipantsType = {users: number[]; extraUsers: FindUserModalDTO[]; init: boolean}
type ParticipantsProps = {
    participantId?: number | string
    participants: ParticipantsType
    setParticipants: SetState<ParticipantsType>
}

export default function Participants({participantId, participants, setParticipants}: ParticipantsProps) {
    const [expanded, setExpanded] = React.useState<number>()
    const [showAddMember, setShowAddMember] = React.useState(false)
    const allDistricts = DistrictsExport(!!participantId)
    const {data, isLoading, isError} = useSkilQuery<'getPartnershipStatsItem'>(
        `/api/partnerships/statistics/${participantId}`,
        {},
        {
            enabled: !!participantId,
            onSuccess: data => {
                if (!participants.init) {
                    let users: number[] = []
                    data.offices.forEach(office => {
                        const officeUsers = office.users.map(dto => dto.user!.id)
                        users.concat(officeUsers)
                    })

                    setParticipants({users, extraUsers: [], init: true})
                }
                return data
            },
        }
    )
    const offices = data?.offices ?? []
    const officesWithDistricts = offices.filter(o => o.office?.district)
    const officesWithoutDistricts = offices.filter(o => !o.office?.district)
    const county = offices[0]?.office?.county
    const districts = allDistricts.filter(d => '/api/counties/' + d.county === county)

    const toggleUser = ({id, ...rest}, checked) => {
        setParticipants(({users, ...participants}) => {
            if (checked) {
                // only include the user if it's not already in the list
                if (!users.includes(id)) {
                    return {...participants, users: [...users, id]}
                }
                return {...participants, users: [...users]}
            } else {
                return {...participants, users: users.filter(oldId => oldId !== id)}
            }
        })
    }

    const toggleOffice = (office, checked) => {
        office.users.forEach(d => toggleUser(d.user, checked))
    }

    const onAddMember = addUser => {
        setParticipants(participants => {
            if (
                !participants.extraUsers.some(u => u.id === addUser.id) ||
                (addUser.type === 'new' && !participants.extraUsers.some(u => u.email === addUser.email))
            ) {
                participants.extraUsers.push(addUser)
            } else {
                toast('Deltaker er allerede lagt til', {type: 'warning'})
            }
            return {...participants}
        })
        setShowAddMember(false)
    }

    const removeExtraUser = index => {
        setParticipants(participants => {
            const newList = [...participants.extraUsers]
            newList.splice(index, 1)
            participants.extraUsers = newList
            return {...participants}
        })
    }

    if (isLoading) return <LoadingComponent msg={'Laster inn legekontor...'} absolute={false} />

    if (isError) return <ErrorComponent>Kunne ikke laste inn legene!</ErrorComponent>

    return (
        <Collapse expanded={(Array.isArray(offices) && (offices?.length ?? 0)) > 0}>
            {districts &&
                districts.length > 0 &&
                districts.map(district => {
                    const districtHasOffices = officesWithDistricts.some(o => o.office?.district === district?.['@id'])
                    if (!districtHasOffices) {
                        return null
                    }

                    return (
                        <div key={district.id}>
                            <strong>{district.label}</strong>
                            <br />
                            <br />
                            {!districtHasOffices && (
                                <div>
                                    <p>Ingen leger registrert</p>
                                    <br />
                                </div>
                            )}
                            {Array.isArray(officesWithDistricts) &&
                                officesWithDistricts.map(o => {
                                    let selected = o.users.filter(d => participants.users.includes(d.user!.id)).length
                                    if (o.office?.district !== '/api/districts/' + district.id) {
                                        return null
                                    }
                                    return (
                                        <OfficeComponent
                                            key={o.office?.id}
                                            participants={participants}
                                            setParticipants={setParticipants}
                                            toggleUser={toggleUser}
                                            toggleOffice={toggleOffice}
                                            o={o}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                            selected={selected}
                                        />
                                    )
                                })}
                        </div>
                    )
                })}
            {districts && officesWithoutDistricts && districts.length > 0 && officesWithoutDistricts.length > 0 && (
                <div>
                    <strong>Ingen bydel registrert</strong>
                    <br />
                    <br />
                    {officesWithoutDistricts &&
                        (officesWithoutDistricts ?? []).map(o => {
                            let selected = o.users.filter(d => participants.users.includes(d.user!.id)).length

                            return (
                                <OfficeComponent
                                    participants={participants}
                                    setParticipants={setParticipants}
                                    toggleUser={toggleUser}
                                    toggleOffice={toggleOffice}
                                    o={o}
                                    expanded={expanded}
                                    setExpanded={setExpanded}
                                    selected={selected}
                                />
                            )
                        })}
                </div>
            )}
            {districts &&
                districts.length === 0 &&
                Array.isArray(offices) &&
                (offices ?? []).map(o => {
                    let selected = o.users.filter(d => participants.users.includes(d.user!.id)).length

                    return (
                        <OfficeComponent
                            participants={participants}
                            setParticipants={setParticipants}
                            toggleUser={toggleUser}
                            toggleOffice={toggleOffice}
                            o={o}
                            expanded={expanded}
                            setExpanded={setExpanded}
                            selected={selected}
                        />
                    )
                })}
            <div>
                <div>
                    <label>Flere deltakere</label>
                </div>
                <ul className={'list-group'}>
                    {participants.extraUsers.map((u, index) => (
                        <li className={'list-group-item'} key={u.type + (u.type === 'new' ? u.name + u.email : u.id)}>
                            {u.name}{' '}
                            <Button variant={'link'} inline onClick={() => removeExtraUser(index)}>
                                Fjern
                            </Button>
                        </li>
                    ))}
                </ul>
                <Button variant={'link'} onClick={() => setShowAddMember(true)}>
                    Legg til deltaker
                </Button>
                {showAddMember && <FindUserModal onClose={() => setShowAddMember(false)} onSave={onAddMember} />}
            </div>
        </Collapse>
    )
}
