import * as React from 'react'
import {Box} from '@mui/material'
import QualitySeminarGoal24 from './QualitySeminarGoal24'
import NewParticipantsGoal24 from './NewParticipantsGoal24'
import NewUserCoursesGoal24 from './NewUserCoursesGoal24'
import QualitySeminarsGoal24 from './QualitySeminarsGoal24'
import NefleStats from './NefleStats'

export const Goals24 = () => {
    return (
        <Box sx={{display: 'flex', p: 6, gap: 4, justifyContent: 'center'}}>
            <QualitySeminarGoal24 />
            <NewParticipantsGoal24 />
            <NewUserCoursesGoal24 />
            <QualitySeminarsGoal24 />
            <NefleStats />
            {/* other guys forthcoming! */}
            {/* <KvalitetspakkerGoal /> */}
            {/* <KursbevisGoal /> */}
        </Box>
    )
}
