import PurePage from '../../../Components/PurePage'
import {BackdropComponent} from '../../../Components/BackdropComponent'
import React, {useEffect, useState} from 'react'
import useUser from '../../../Utilities/useUser'
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    Chip,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Typography,
    List,
    ListItemButton,
    ListItemAvatar,
    Avatar,
    Skeleton,
} from '@mui/material'
import dayjs from 'dayjs'
import {LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import 'dayjs/locale/nb'
import {MobileDateTimePicker} from '@mui/x-date-pickers/MobileDateTimePicker'
import DialogActions from '@mui/material/DialogActions'
import Item from '@mui/material/Unstable_Grid2'
import {Button} from '../../../Components/Button/MuiButton'
import {useSkilMutation, useSkilQuery} from '../../../Utilities/QueryClient'
import styled from 'styled-components'
import {z} from 'zod'
import {useNavigate, useParams} from 'react-router'
import {zodResolver} from '@hookform/resolvers/zod'
import TextField from '@mui/material/TextField'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import TermsOfServiceDialog from './TermsOfServiceDialog'
import {Controller, SubmitHandler, useFieldArray, useForm, UseFormProps, useWatch} from 'react-hook-form'
import {toast} from 'react-toastify'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import CircularProgress from '@mui/material/CircularProgress'
import FormHelperText from '@mui/material/FormHelperText'
import PersonIcon from '@mui/icons-material/Person'
import {useQuery} from 'react-query'
import InvoiceDetails from './InvoiceDetails'
import LearningGoalsDialog from './LearningGoalsDialog'

export const INVOICE_RECEIVER_PRIVATE = 1
export const INVOICE_RECEIVER_SOLE_PROPRIETORSHIP = 2
export const INVOICE_RECEIVER_COMPANY = 3

const StyledDateTimePickerFormControlLabel = styled(FormControlLabel)`
    .MuiDialog-paper {
        min-width: auto;
    }
`

const StyledGrid = styled(Grid)`
    .MuiCard-root {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`
const StyledFormControlLabel = styled(FormControlLabel)`
    .MuiInputBase-root {
        margin-bottom: 0px;
    }
`
const StyledAddButton = styled(Button)`
    margin-top: 1em;
`

export const Section = styled.div`
    margin-top: 4em;
`
export const SmallSection = styled.div`
    margin-top: 2em;
`
const SectionDivider = styled.div`
    margin-top: 2em;
`
const SelectedGroupExplainer = styled.div`
    display: inline-grid;
    width: 100%;
`
export const LearningGoalContainer = styled.div`
    margin-bottom: 1em;
`
export const TextHeading = styled.h5`
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    text-align: left;
`
const SectionHeading = styled.h5`
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 2em;
`
export const Title = styled.h5`
    font-weight: 700;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    overflow-wrap: break-word;
`
const NewGroupSection = styled.div`
    display: inline-grid;
    width: 100%;
`
const GroupButton = styled(Button)`
    width: fit-content;
    margin-top: 1rem;
`

export const StyledLink = styled.span`
    font-size: 14px;
    margin-left: 0.5em;
`

const UserResult = styled.span`
    font-size: 12px;
`

export const SkeletonTitleContainer = styled.div`
    display: flex;
    justify-content: center;
`

const dateSchema = z.coerce.date()

type GroupsType = {
    activeCourse: string
    email: string
    group: string
    groupName: string
    isAdmin: boolean
    isLeader: boolean
    member: string
    name: string
    newestMeetingCourse: string
    newestMeetingDate: string
}[]

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const timeFormat: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
}

function isIsoDate(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false
    const d = new Date(str)
    return !isNaN(d.getTime()) && d.toISOString() === str // valid date
}

const validationSchema = z.object({
    meeting: z.object({
        place: z.string().nonempty({message: 'Sted er påkrevd'}),
        time: z.string().refine(
            time => {
                if (isIsoDate(time)) {
                    // onChange has the correct format so not conversion is needed
                    return dateSchema.safeParse(new Date(time)).success
                } else {
                    // because of adapterLocale='nb' used in LocalizationProvider, the date submitted is in the format 'dd.mm.yyyy hh:mm'
                    // so it needs to be converted to 'mm-dd-yyyy hh:mm' before it can be parsed by dateSchema
                    let day = time.substring(0, 2)
                    let month = time.substring(3, 5)
                    let year = time.substring(6, 10)
                    let hour = time.substring(11, 16)
                    const converted = month + '-' + day + '-' + year + ' ' + hour
                    return dateSchema.safeParse(new Date(converted)).success
                }
            },
            {
                message: 'Ugyldig dato',
            }
        ),
    }),
    members: z.array(
        z.object({
            name: z.string().nonempty({message: 'Navn er påkrevd'}),
            email: z.string().email({message: 'Ugyldig e-post'}),
            member: z.string().optional(),
            group: z.string().optional(),
        })
    ),
    tosAccepted: z.optional(
        z
            .boolean({
                required_error: 'Du må godkjenne vilkår for å fortsette',
            })
            .refine(tosAccepted => tosAccepted, {
                message: 'Du må godkjenne vilkår for å fortsette',
            })
    ),
    newGroupName: z.object({title: z.string()}).optional(),

    invoice: z.optional(
        z.object({
            private: z.optional(
                z.object({
                    name: z.string().nonempty({message: 'Navn er påkrevd'}),
                    address: z.string().nonempty({message: 'Adresse er påkrevd'}),
                    postalcode: z.string().refine(
                        code => {
                            return /^\d{4}$/.test(code)
                        },
                        {
                            message: 'Postnummer må være 4 siffer',
                        }
                    ),
                    postalarea: z.string().nonempty({message: 'Poststed er påkrevd'}),
                })
            ),
            company: z.optional(
                z
                    .object({
                        orgnr: z.string().refine(
                            orgnr => {
                                return /^\d{9}$/.test(orgnr)
                            },
                            {message: 'Organisasjonsnummeret må være 9 siffer'}
                        ),
                        orgForm: z.string().nonempty({message: 'Organisasjonsform er påkrevd'}),
                        name: z.string().nonempty({message: 'Organisasjonsnavn er påkrevd'}),
                        email: z
                            .string({
                                required_error: 'E-post er påkrevd',
                                invalid_type_error: 'E-post er ugyldig',
                            })
                            .email({message: 'Ugyldig e-post'}),
                        reference: z.string().optional(),
                        resource: z.string(),
                    })
                    .refine(
                        ({orgForm, resource}) => {
                            if (orgForm === 'KOMM') {
                                return resource !== '' && resource !== undefined
                            } else {
                                return true
                            }
                        },
                        {
                            path: ['resource'],
                            message: 'Påkrevd når kommune faktureres',
                        }
                    )
            ),
            sole_proprietorship: z.optional(
                z.object({
                    orgnr: z.string().refine(orgnr => /^\d{9}$/.test(orgnr), {message: 'Organisasjonsnummeret må være 9 siffer'}),
                    orgForm: z.string().nonempty({message: 'Organisasjonsform er påkrevd'}),
                    name: z.string().nonempty({message: 'Organisasjonsnavn er påkrevd'}),
                    address: z.string().nonempty({message: 'Addresse er påkrevd'}),
                    postalcode: z
                        .string({
                            required_error: 'Postnummer er påkrevd',
                            invalid_type_error: 'Postnummeret er ugyldig',
                        })
                        .refine(
                            code => {
                                return /^\d{4}$/.test(code)
                            },
                            {
                                message: 'Postnummer må være 4 siffer',
                            }
                        ),
                    postalarea: z.string().nonempty({message: 'Poststed er påkrevd'}),
                })
            ),
        })
    ),
})

type ValidationSchema = z.infer<typeof validationSchema>
type SearchItem = {
    text: string
    url: string
    icon: string
    id: number
    '@id': string
    eid: string
    type: string
}
type ApiResponse = {
    results: SearchItem[]
}

function useZodForm<TSchema extends z.ZodType>(
    props: Omit<UseFormProps<TSchema['_input']>, 'resolver'> & {
        schema: TSchema
    }
) {
    return useForm<TSchema['_input']>({
        ...props,
        resolver: zodResolver(props.schema, undefined, {
            // This makes it so we can use `.transform()`s on the schema without same transform getting applied again when it reaches the server
            // @ts-expect-error
            rawValues: true,
        }),
    })
}

export default function GroupSignupPage() {
    const {
        register,
        control,
        handleSubmit,
        formState: {isValid, errors, isDirty},
        unregister,
        trigger,
        setValue,
        clearErrors,
        setError,
    } = useZodForm({
        schema: validationSchema,
        mode: 'all',
        defaultValues: {
            meeting: {
                place: '',
                time: '',
            },
            tosAccepted: false,
            invoice: {
                private: {
                    name: '',
                    address: '',
                    postalcode: '',
                    postalarea: '',
                },
                company: {
                    orgnr: '',
                    orgForm: '',
                    email: '',
                    reference: '',
                    resource: '',
                },
                sole_proprietorship: {
                    orgnr: '',
                    orgForm: '',
                    name: '',
                    address: '',
                    postalcode: '',
                    postalarea: '',
                },
            },
        },
    })

    const user = useUser()
    const navigate = useNavigate()
    const {shortTitle} = useParams<{shortTitle: string}>()
    const signupGroupToCourseMutation = useSkilMutation<'signupGroupToCourse'>('POST', `/api/groups/signupToCourse`)
    const [selectedGroup, setSelectedGroup] = React.useState<string | undefined>(undefined)
    const [selectedGroupName, setSelectedGroupName] = React.useState<string | undefined>(undefined)
    const [meetingTime, setMeetingTime] = React.useState<string>('')
    const [meetingPlace, setMeetingPlace] = React.useState<string>('')
    const [tosAccepted, setTosAccepted] = React.useState<boolean>(false)
    const [termsOfServiceDialogOpen, setTermsOfServiceDialogOpen] = React.useState(false)
    const [learningGoalsDialogOpen, setLearningGoalsDialogOpen] = React.useState(false)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const formRef = React.useRef<HTMLFormElement>(null)
    const {data: course} = useSkilQuery<'getCoursePublicInfoByShortTitle'>(`/api/courses/${shortTitle}/publicInfo/byShortTitle`)
    const {data: userCourseResponse} = useSkilQuery<'userCourseByUserAndCourse'>(
        user.id && course?.id ? `/api/user_courses/forGroupSignup` : '',
        {
            courseId: course?.id,
        }
    )
    const {data: invoiceResponse} = useSkilQuery<'invoiceDetailsByUserAndCourse'>(
        user.id && course?.id ? `/api/user_courses/invoice_details` : '',
        {
            courseId: course?.id,
        }
    )
    const userCourse = userCourseResponse?.['hydra:member']?.[0]
    const invoice = invoiceResponse?.['hydra:member']?.[0]
    const {data: groupsAndMembers} = useSkilQuery<'getUserGroupsAndMembers'>(`/api/users/${user.id}/groupsAndMembers`)
    const {data: newGroupName} = useQuery<{title: string}>(`/api/groups/newGroupName`)
    const [existingUserIri, setExistingUserIri] = React.useState<string>('')
    const {data: existingUser} = useSkilQuery<'getUserForGroupInvite'>(existingUserIri ? existingUserIri + '/groupInvite' : '')
    const readyToShow = course && groupsAndMembers?.['@id'] && newGroupName?.title && user.authenticated
    const [newNameHasFocus, setNewNameHasFocus] = React.useState(false)
    const [isAddingUser, setIsAddingUser] = React.useState(false)
    const [search, setSearch] = useState({value: '', index: 0})
    const [isSearchingOrgNr, setIsSearchingOrgNr] = useState(false)
    const [canSignUpToCourse, setCanSignUpToCourse] = React.useState<boolean>(false)
    const courseNotFound = course?.['hydra:description'] === 'Not Found' || course?.['@type'] === 'hydra:Error'

    // make sure that the course exists, is not archived, is published, is not a seminar type , is not a forum type, if not then redirect to frontpage
    // course of seminar or forum type should not be allowed to signup with a group
    const signupPossible = React.useMemo(
        () => course?.signupAllowed && !courseNotFound && course?.type !== 2 && course?.type !== 3,
        [course]
    )

    const hasValidInvoiceDetails = React.useMemo(() => {
        if (
            userCourse?.isCourseAcknowledged &&
            !!invoice &&
            !!userCourse.jiraInvoiceTaskId &&
            userCourse.jiraInvoiceTaskId &&
            !!invoice.invoiceDetailsConfirmedAt
        ) {
            // if the user has already registered the invoice details and the jira task has been created then we do not need to collect the invoice details again
            // so set the user terms as accepted so that the user can submit the form
            setValue('tosAccepted', true)
            return true
        } else {
            return false
        }
    }, [userCourse, invoice])

    // if there is no userCourse then it is a new signup and the user pays the price of the course is the price is greater than 0
    function calculateIfUserShouldPay() {
        if (userCourse && (!course?.price || course?.price === 0 || !userCourse?.price || userCourse?.price === 0)) {
            return false
        }
        if (!userCourse && course?.price === 0) {
            return false
        }
        return true
    }

    const userShouldPay = React.useMemo(() => calculateIfUserShouldPay(), [userCourse, course])

    useEffect(() => {
        // if user is not authenticated then navigate to login page with redirect to this page
        if (!user.authenticated) {
            navigate(`/signup/${shortTitle}/${encodeURIComponent(window.location.pathname)}`)
        }
    }, [user])

    const onChangeFocus = () => {
        sleep(200).then(() => {
            setNewNameHasFocus(false)
        })
    }

    const privatePostalarea = useWatch({
        control,
        name: 'invoice.private.postalarea',
        defaultValue: '',
    })

    function delayRedirect(url: string) {
        const timer = setTimeout(() => {
            window.location.replace(url)
        }, 1500)

        return () => clearTimeout(timer)
    }

    //ugly hack to trigger validation by changing focus,
    //else the validation of existing invoice details for private person is not triggered when programmatically setting the fields in InvoiceDetails.tsx
    useEffect(() => {
        if (privatePostalarea && invoice?.invoiceReceiver === INVOICE_RECEIVER_PRIVATE) {
            document.getElementById('invoice.private.postalarea')?.focus()
            setTimeout(() => {
                document.getElementById('private_button')?.focus()
            }, 500)
            trigger().then(() => {})
        }
    }, [privatePostalarea])

    const {
        data: dataResults,
        isLoading: isLoadingSearchResults,
        // @ts-expect-error
    } = useQuery<ApiResponse>(`/dashboard/api/search?term=${search.value}&filter=users&excludeInvalidEmails=true&isSignup=true`, {
        enabled: search.value.length >= 3,
        select: (data: ApiResponse, err) => {
            if (err) return
            return data?.results ?? {}
        },
    })

    const searchResults = dataResults as unknown as SearchItem[]

    useEffect(() => {
        if (!course) return
        if (!signupPossible) {
            setCanSignUpToCourse(false)
            // if the course is a seminar type then redirect to the frontpage
            if (course?.type === 2) {
                handleErrorWithToast(new Error('Du kan ikke melde gruppen på et seminar'))
            }
            // if the course is a conference type then redirect to the frontpage
            if (course?.type === 3) {
                handleErrorWithToast(new Error('Du kan ikke melde gruppen på en konferanse'))
            }
            // if the course is not signupAllowed then redirect to the frontpage
            if (!course?.signupAllowed) {
                handleErrorWithToast(new Error('Kurset er ikke åpent for påmelding'))
            }
            // if the course is not found then redirect to the frontpage
            if (courseNotFound) {
                handleErrorWithToast(new Error('Dette kurset finnes ikke'))
            }
            // if the course is not found then redirect to the frontpage
            if (course?.['@type'] === 'hydra:Error' && !courseNotFound) {
                handleErrorWithToast(new Error('En feil har oppstått'))
            }
            delayRedirect(`${window.location.protocol}//${window.location.hostname}`)
        } else {
            setCanSignUpToCourse(true)
        }
    }, [course])

    useEffect(() => {
        if (searchResults?.length > 0) {
            setNewNameHasFocus(true)
        }
    }, [searchResults])

    useEffect(() => {
        if (existingUser?.['@id']) {
            updateMemberFields(search.index, {
                member: existingUser['@id'],
                group: selectedGroup,
                name: existingUser.name,
                email: existingUser.email,
            })
            setExistingUserIri('')
            setIsAddingUser(false)
            setSearch({value: '', index: 0})
            trigger('members').then(r => {})
        }
    }, [existingUser])

    const addExisitingUser = iri => {
        const alreadyExists = memberFields.find(m => m.member === iri)
        if (alreadyExists) {
            handleErrorWithToast(new Error('Brukeren er allerede lagt til'))
            setNewNameHasFocus(false)
            return
        } else {
            setExistingUserIri(iri)
            setNewNameHasFocus(false)
            setIsAddingUser(true)
        }
    }

    // @ts-expect-error
    const emailsHasError = errors?.members?.filter(m => m?.email)?.length > 0
    const {
        fields: memberFields,
        append: appendMemberFields,
        remove: removeMemberFields,
        update: updateMemberFields,
    } = useFieldArray({
        keyName: undefined,
        name: 'members',
        rules: undefined,
        control,
    })
    let members = groupsAndMembers?.['hydra:member']
    members?.sort(function (a, b) {
        // @ts-expect-error
        if (a.newestMeetingDate > b.newestMeetingDate) {
            return -1
        }
        // @ts-expect-error
        if (a.newestMeetingDate < b.newestMeetingDate) {
            return 1
        }
        return 0
    })
    // @ts-expect-error
    const groups: GroupsType[] = members
        ? members?.reduce((g, member) => {
              const {group} = member
              g[group] = g[group] ?? []
              g[group].push(member)
              return g
          }, {})
        : {}

    const hasPreviousGroups = Object.keys(groups).length > 0

    useEffect(() => {
        // if there are no groups, and the user is authenticated, add a new group
        if (!hasPreviousGroups && groupsAndMembers?.['hydra:totalItems'] === 0 && newGroupName?.title) {
            onSelectGroup('new_group')
        }
    }, [groupsAndMembers, newGroupName])

    useEffect(() => {
        // if the user should not pay
        // or we already have the invoice details and have already made the jira task,
        // then we do not collect the invoice details
        // so we need to remove the fields so that they are not validated

        if (!userShouldPay || hasValidInvoiceDetails) {
            unregister([
                'invoice.private.name',
                'invoice.private.address',
                'invoice.private.postalcode',
                'invoice.private.postalarea',
                'invoice.company.orgnr',
                'invoice.company.orgForm',
                'invoice.company.name',
                'invoice.company.email',
                'invoice.company.reference',
                'invoice.company.resource',
                'invoice.sole_proprietorship.orgnr',
                'invoice.sole_proprietorship.orgForm',
                'invoice.sole_proprietorship.name',
                'invoice.sole_proprietorship.address',
                'invoice.sole_proprietorship.postalcode',
                'invoice.sole_proprietorship.postalarea',
            ])
        }
    }, [course, userCourse])

    const onSelectGroup = (group: string) => {
        setSelectedGroup(group)
        if (group === 'new_group' && newGroupName?.title) {
            removeMemberFields()
            if (user.authenticated) {
                appendMemberFields([
                    {member: user.iri, group: undefined, name: user.claims.name, email: user.claims.email},
                    {member: undefined, group: undefined, name: '', email: ''},
                ])
            }
            setSelectedGroupName(newGroupName?.title)
        } else {
            removeMemberFields()
            appendMemberFields(groups[group].map(m => ({member: m.member, group: m.group, name: m.name, email: m.email})))
            setSelectedGroupName(groups[group][0].groupName)
        }
    }

    const onAddMember = () => {
        if (memberFields?.filter(m => m?.email === '' || m?.name === '')?.length === 0) {
            appendMemberFields([{member: undefined, group: selectedGroup === 'new_group' ? undefined : selectedGroup, name: '', email: ''}])
        }
    }

    const onRemoveMember = (index: number) => {
        // a group should have at least 2 members
        if (memberFields?.length > 2) {
            removeMemberFields(index)
        } else {
            handleErrorWithToast(
                new Error(
                    'Kan ikke fjerne medlemmet. En gruppe må ha minst 2 medlemmer. Legg til et annet medlem først, så kan du fjerne dette.'
                )
            )
        }
    }

    const onSubmit: SubmitHandler<ValidationSchema> = async data => {
        if (isSubmitting || !course || !shortTitle) {
            return
        }
        setIsSubmitting(true)
        try {
            await signupGroupToCourseMutation
                .mutateAsync({
                    group: selectedGroup === 'new_group' ? undefined : selectedGroup,
                    members: memberFields,
                    place: data.meeting.place,
                    time: meetingTime,
                    // @ts-expect-error // it will always be boolean
                    tosAccepted: data.tosAccepted,
                    shortTitle: shortTitle,
                    newGroupName: newGroupName?.title,
                    // @ts-expect-error
                    invoice: data.invoice,
                })
                .then(group => {
                    if (group?.id) {
                        user.refresh()
                        toast(`Gruppen ble invitert til kurset ${course.title}`, {type: 'success'})
                        delayRedirect(`${window.location.protocol}//${window.location.hostname}/dashboard/groups/${group.id}`)
                    } else {
                        handleErrorWithToast(' Det skjedde en feil under påmeldingen. Prøv igjen.')
                        setIsSubmitting(false)
                    }
                })
        } catch (e) {
            handleErrorWithToast(e + ' Det skjedde en feil under påmeldingen. Prøv igjen.')
            setIsSubmitting(false)
        }
    }

    // replace %20 with space
    const shortTitleClean = shortTitle?.replace('%20', ' ')

    const onLocalChangeDateTime = time => {
        setMeetingTime(time)
    }

    const isSubmittable = isDirty && isValid && memberFields.length >= 2 && !isSearchingOrgNr
    // only show the form for collecting the invoice details if the user has filled in the meeting time and date,
    // has accepted the terms of service and the user should not pay
    const isReadyToShowInvoiceDetails = meetingTime && meetingPlace && tosAccepted && userShouldPay && !hasValidInvoiceDetails

    return (
        <PurePage variant={'md'}>
            <form ref={formRef} noValidate onSubmit={handleSubmit(onSubmit)}>
                {readyToShow && (
                    <>
                        <Box sx={{backgroundColor: 'white', borderRadius: '0.25em', padding: {xs: '1em', sm: '2em'}}}>
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: {xs: '1em', sm: '2em'}}}>
                                {/* @ts-expect-error */}
                                <img width={'20%'} id='course-logo' src={course?.logo} alt={course?.shortTitle} />
                            </Box>
                            <Title>{course ? course.title : ''}</Title>
                            <Section>
                                <TextHeading>
                                    <strong>Gruppepåmelding</strong>
                                </TextHeading>
                                <p>
                                    Den som registrerer gruppen blir gruppeleder, legger til deltakere og oppretter gruppemøter samt har
                                    ansvar for å følge opp gruppen underveis i kursforløpet.
                                </p>
                            </Section>
                            {selectedGroup && newGroupName.title && (
                                <SectionDivider>
                                    <Divider sx={{mt: 0, mb: 4, ml: 0, mr: 0}} variant='middle' />
                                    <SelectedGroupExplainer>
                                        {selectedGroup === 'new_group' && hasPreviousGroups && (
                                            <p>
                                                Du har valgt å melde på en ny gruppe. Gruppen får navnet{' '}
                                                <strong>{selectedGroupName}</strong>. Du kan legge til eller fjerne gruppemedlemmer
                                                nedenfor. Kun valgte gruppemedlemmer blir meldt på kurset. Fjerning av gruppemedlemmer
                                                påvirker ikke eksisterende gruppesammensetningen.
                                            </p>
                                        )}
                                        {selectedGroup === 'new_group' && !hasPreviousGroups && (
                                            <p>
                                                Det opprettes en ny gruppe som får navnet <strong>{selectedGroupName}</strong>. Du kan endre
                                                eller legge til flere medlemmer nedenfor.
                                            </p>
                                        )}
                                        {selectedGroup !== 'new_group' && (
                                            <>
                                                <p>
                                                    Du har valgt å melde på gruppen <strong>{selectedGroupName}</strong>. Du kan legge til
                                                    eller fjerne gruppemedlemmer nedenfor. Kun valgte gruppemedlemmer blir meldt på kurset.
                                                    Fjerning av gruppemedlemmer påvirker ikke eksisterende gruppesammensetningen.
                                                </p>
                                            </>
                                        )}
                                        {hasPreviousGroups && (
                                            <GroupButton variant='contained' onClick={() => setSelectedGroup('')}>
                                                Velg en annen gruppe
                                            </GroupButton>
                                        )}
                                    </SelectedGroupExplainer>
                                </SectionDivider>
                            )}
                            {hasPreviousGroups && !selectedGroup && (
                                <SectionDivider>
                                    <Divider sx={{mt: 0, mb: 4, ml: 0, mr: 0}} variant='middle' />
                                    <NewGroupSection>
                                        <p>
                                            Opprett en ny gruppe eller velg en tidligere gruppe. Du kan legge til eller fjerne
                                            gruppemedlemmer i neste steg.
                                        </p>
                                        <GroupButton variant='contained' onClick={() => onSelectGroup('new_group')}>
                                            Opprett ny gruppe
                                        </GroupButton>
                                    </NewGroupSection>
                                    <Divider sx={{mt: 4, mb: 4, ml: 0, mr: 0}} variant='middle'>
                                        <strong>eller</strong>
                                    </Divider>
                                    <SectionHeading>
                                        <strong>Tidligere grupper</strong>
                                    </SectionHeading>
                                    <Grid container spacing={2}>
                                        {Object.values(groups)?.map((group, index) => {
                                            const meetingDate = group
                                                ? new Date(group[0].newestMeetingDate).toLocaleDateString('nb-NO')
                                                : ''
                                            const meetingTime = group
                                                ? new Date(group[0].newestMeetingDate).toLocaleTimeString('nb-NO', timeFormat)
                                                : ''
                                            const now = new Date()
                                            const isPast = group ? new Date(group[0].newestMeetingDate) < now : false
                                            return (
                                                <StyledGrid
                                                    key={group[0].group}
                                                    display={'flex'}
                                                    width={'100%'}
                                                    xs={12}
                                                    sm={6}
                                                    md={6}
                                                    item
                                                    sx={{justifyContent: {xs: 'center', sm: 'initial'}}}
                                                >
                                                    <Card variant='outlined'>
                                                        <CardContent>
                                                            <Box sx={{my: {xs: 1, sm: 3}, mx: {xs: 0, sm: 2}}}>
                                                                <Item container alignItems='center'>
                                                                    <Item>
                                                                        <Typography gutterBottom variant='h5' component='div'>
                                                                            {group[0].groupName}
                                                                        </Typography>
                                                                    </Item>
                                                                </Item>
                                                                <Typography color='text.secondary' variant='body2'>
                                                                    {isPast ? 'Forrige gruppemøte var' : 'Neste gruppemøte er'}{' '}
                                                                    {meetingDate} {!isPast ? 'klokken ' + meetingTime : ''} for kurset{' '}
                                                                    {group[0].newestMeetingCourse}
                                                                </Typography>
                                                            </Box>
                                                            <Divider sx={{mt: 4, mb: 4, ml: 0, mr: 0}} variant='middle' />
                                                            <Box sx={{m: {xs: 0, sm: 2}}}>
                                                                <Typography gutterBottom variant='body1'>
                                                                    Gruppemedlemmer
                                                                </Typography>
                                                                <Stack spacing={1} direction={{xs: 'column', sm: 'row'}} flexWrap='wrap'>
                                                                    {group.map(member => {
                                                                        return (
                                                                            <Chip
                                                                                key={member.member + member.group}
                                                                                style={{marginLeft: '0.5em', marginTop: '0.5em'}}
                                                                                label={member.name}
                                                                            />
                                                                        )
                                                                    })}
                                                                </Stack>
                                                            </Box>
                                                        </CardContent>
                                                        <DialogActions sx={{paddingX: 3, paddingBottom: 2}}>
                                                            <Button variant='contained' onClick={() => onSelectGroup(group[0].group)}>
                                                                Bruk gruppen
                                                            </Button>
                                                        </DialogActions>
                                                    </Card>
                                                </StyledGrid>
                                            )
                                        })}
                                    </Grid>
                                </SectionDivider>
                            )}
                            {selectedGroup && memberFields && (
                                <SectionDivider>
                                    <Divider sx={{mt: 0, mb: 4, ml: 0, mr: 0}} variant='middle' />
                                    <TextHeading>
                                        <strong>Registrer deltakerne i gruppen (minimum 2 deltakere):</strong>
                                    </TextHeading>
                                    {memberFields.map((member, index) => {
                                        const lastIndex = memberFields.length - 1
                                        const isLastElement = lastIndex === index
                                        const isSearching = isLastElement && isLoadingSearchResults
                                        return (
                                            <div key={member?.group ? member?.group + index : 'group' + index}>
                                                <Box
                                                    component={'div'}
                                                    sx={{
                                                        display: {xs: member.member ? 'flex' : 'block', sm: 'flex'},
                                                        alignItems: 'center',
                                                        mt: {xs: 2, sm: 1},
                                                    }}
                                                >
                                                    <Controller
                                                        name={`members.${index}.name`}
                                                        control={control}
                                                        render={({field: {onChange, value}, fieldState: {}}) => (
                                                            <FormGroup
                                                                row
                                                                sx={{
                                                                    width: {xs: '100%', sm: member.member ? '100%' : '80%'},
                                                                    mt: {xs: 0, sm: 0},
                                                                }}
                                                            >
                                                                <StyledFormControlLabel
                                                                    sx={{
                                                                        pointerEvents: 'none',
                                                                        width: {xs: '100%', sm: '100%'},
                                                                        mr: {xs: 0, sm: 0},
                                                                        ml: {xs: 0, sm: 0},
                                                                        mb: {xs: 0, sm: 0},
                                                                        borderRadius: '0.25em',
                                                                    }}
                                                                    control={
                                                                        <TextField
                                                                            id={`members.${index}.name`}
                                                                            type={'text'}
                                                                            value={member.name}
                                                                            placeholder={'Navn'}
                                                                            sx={{
                                                                                pointerEvents: 'auto',
                                                                                width: {xs: '100%', sm: '100%'},
                                                                                mr: {xs: 0, sm: 1},
                                                                                ml: {xs: 0, sm: 0},
                                                                                mt: {xs: 0, sm: 1},
                                                                                mb: {xs: 0, sm: 1},
                                                                            }}
                                                                            disabled={!!member.member}
                                                                            {...register(`members.${index}.name` as const)}
                                                                            error={!!errors?.members?.[index]?.name}
                                                                            helperText={errors?.members?.[index]?.name?.message ?? ''}
                                                                            name={`members.${index}.name`}
                                                                            onChange={e => {
                                                                                if (e.target) {
                                                                                    setSearch({
                                                                                        value: e.target.value,
                                                                                        index: index,
                                                                                    })
                                                                                    updateMemberFields(index, {
                                                                                        member: member.member ?? undefined,
                                                                                        group: member.group ?? undefined,
                                                                                        name: e.target.value,
                                                                                        email: member.email,
                                                                                    })
                                                                                    onChange(e)
                                                                                }
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <>
                                                                                        {isSearching && (
                                                                                            <InputAdornment position='end'>
                                                                                                <span style={{fontSize: '0.7em'}}>
                                                                                                    Søker
                                                                                                </span>{' '}
                                                                                                <IconButton
                                                                                                    sx={{p: 0, ml: 1}}
                                                                                                    aria-label='marker'
                                                                                                >
                                                                                                    <CircularProgress
                                                                                                        color='inherit'
                                                                                                        size={'1em'}
                                                                                                    />
                                                                                                </IconButton>
                                                                                            </InputAdornment>
                                                                                        )}
                                                                                    </>
                                                                                ),
                                                                            }}
                                                                            onFocus={() => setNewNameHasFocus(true)}
                                                                            onMouseEnter={() => setNewNameHasFocus(true)}
                                                                            onBlur={() => onChangeFocus()}
                                                                        />
                                                                    }
                                                                    value={value}
                                                                    label={''}
                                                                />
                                                            </FormGroup>
                                                        )}
                                                    />
                                                    <Box
                                                        sx={{
                                                            width: {xs: member.member ? 'auto' : '100%'},
                                                            display: {xs: 'flex'},
                                                            alignItems: 'center',
                                                            mt: {xs: member.member ? 0 : 1, sm: member.member ? 0 : 0},
                                                        }}
                                                    >
                                                        <Controller
                                                            name={`members.${index}.email`}
                                                            control={control}
                                                            render={({field: {onChange, value}, fieldState: {}}) => (
                                                                <FormGroup
                                                                    row
                                                                    sx={{
                                                                        width: {xs: '100%', sm: '100%'},
                                                                        display: {
                                                                            xs: member.member ? 'none' : 'initial',
                                                                            sm: member.member ? 'none' : 'initial',
                                                                        },
                                                                    }}
                                                                >
                                                                    <StyledFormControlLabel
                                                                        sx={{
                                                                            pointerEvents: 'none',
                                                                            width: {xs: '100%', sm: '100%'},
                                                                            mr: {xs: 0, sm: 0},
                                                                            ml: {xs: 0, sm: 0},
                                                                            mb: {xs: 0, sm: 0},
                                                                            borderRadius: '0.25em',
                                                                        }}
                                                                        control={
                                                                            <TextField
                                                                                id={`members.${index}.email`}
                                                                                type={'email'}
                                                                                sx={{
                                                                                    pointerEvents: 'auto',
                                                                                    width: {xs: '100%', sm: '100%'},
                                                                                    mr: {xs: 0, sm: 1},
                                                                                    ml: {xs: 0, sm: 1},
                                                                                    mt: {xs: 0, sm: 2},
                                                                                    mb: {xs: 0, sm: 2},
                                                                                }}
                                                                                value={member.email}
                                                                                placeholder={'E-post'}
                                                                                disabled={!!member.member}
                                                                                {...register(`members.${index}.email` as const)}
                                                                                error={!!errors?.members?.[index]?.email}
                                                                                helperText={errors?.members?.[index]?.email?.message ?? ''}
                                                                                name={`members.${index}.email`}
                                                                                onChange={e => {
                                                                                    if (e.target) {
                                                                                        updateMemberFields(index, {
                                                                                            member: member.member ?? undefined,
                                                                                            group: member.group ?? undefined,
                                                                                            email: e.target.value,
                                                                                            name: member.name,
                                                                                        })
                                                                                        onChange(e)
                                                                                    }
                                                                                }}
                                                                            />
                                                                        }
                                                                        value={value}
                                                                        label={''}
                                                                    />
                                                                </FormGroup>
                                                            )}
                                                        />
                                                        <Button
                                                            size={'small'}
                                                            sx={{
                                                                ml: 1,
                                                                minWidth: 'unset',
                                                                height: 'fit-content',
                                                            }}
                                                            variant='outlined'
                                                            onClick={() => onRemoveMember(index)}
                                                        >
                                                            <DeleteOutlinedIcon fontSize='large' />
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                <Divider
                                                    sx={{mt: 2, mb: 2, ml: 0, mr: 0, display: {xs: 'inherit', sm: 'none'}}}
                                                    variant='middle'
                                                />
                                            </div>
                                        )
                                    })}
                                    {searchResults && searchResults?.length > 0 && newNameHasFocus && (
                                        <Box
                                            component={'div'}
                                            sx={{
                                                display: {xs: 'block', sm: 'block'},
                                                alignItems: 'center',
                                                marginTop: {xs: '-2em', sm: '-1em'},
                                            }}
                                        >
                                            <List
                                                dense={true}
                                                sx={{
                                                    pt: 0,
                                                    pb: 0,
                                                    mb: 2,
                                                    width: {xs: '100%', sm: '43%'},
                                                    maxWidth: 360,
                                                    bgcolor: 'background.paper',
                                                    border: '1px solid #dadce0',
                                                    borderRadius: '0.25em',
                                                }}
                                                aria-label='Eksisterende brukere'
                                                onMouseLeave={() => onChangeFocus()}
                                            >
                                                <Typography gutterBottom variant='overline' component='div' sx={{ml: 2, mt: 1, mb: 1}}>
                                                    Velg eksisterende bruker:
                                                </Typography>
                                                {searchResults?.map(user => (
                                                    <div key={user.eid}>
                                                        <Divider />
                                                        <ListItemButton
                                                            sx={{fontSize: '12px'}}
                                                            divider
                                                            onClick={() => addExisitingUser(user?.['@id'])}
                                                        >
                                                            <ListItemAvatar sx={{minWidth: 20, mr: 1}}>
                                                                <Avatar sx={{width: 20, height: 20}}>
                                                                    <PersonIcon />
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <UserResult>{user.text}</UserResult>
                                                        </ListItemButton>
                                                    </div>
                                                ))}
                                            </List>
                                        </Box>
                                    )}
                                    <StyledAddButton
                                        variant='contained'
                                        onClick={() => onAddMember()}
                                        disabled={
                                            memberFields?.filter(m => m?.name === '' || m?.email === '')?.length > 0 || emailsHasError
                                        }
                                    >
                                        + Legg til
                                    </StyledAddButton>
                                </SectionDivider>
                            )}
                            {selectedGroup && (
                                <>
                                    <Section>
                                        <TextHeading>
                                            <strong>Registrer første gruppemøte</strong>
                                        </TextHeading>
                                        <p>Vi anbefaler at dere avtaler første møte minst en måned frem i tid av hensyn til planlegging.</p>
                                        <Controller
                                            name={`meeting.place`}
                                            control={control}
                                            render={({field: {onChange, value}, fieldState: {}}) => (
                                                <StyledFormControlLabel
                                                    sx={{
                                                        width: {xs: '100%', sm: '100%'},
                                                        mr: {xs: 0, sm: 0},
                                                        ml: {xs: 0, sm: 0},
                                                        mb: {xs: 0, sm: 0},
                                                        borderRadius: '0.25em',
                                                    }}
                                                    disabled={false}
                                                    control={
                                                        <TextField
                                                            id='place'
                                                            label='Sted'
                                                            sx={{width: '100%', mb: 2}}
                                                            disabled={false}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment
                                                                        position='start'
                                                                        sx={{cursor: 'not-allowed', pointerEvents: 'none'}}
                                                                    >
                                                                        <IconButton sx={{p: '10px'}} aria-label='marker'>
                                                                            <i className='fa fa-map-marker-alt'></i>{' '}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            {...register('meeting.place')}
                                                            onChange={e => {
                                                                setMeetingPlace(e.target.value)
                                                                onChange(e)
                                                            }}
                                                            value={meetingPlace}
                                                            error={!!errors?.meeting?.place}
                                                            helperText={errors?.meeting?.place?.message ?? ''}
                                                        />
                                                    }
                                                    value={value}
                                                    label={''}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name={`meeting.time`}
                                            control={control}
                                            render={({field: {onChange, value}, fieldState: {}}) => (
                                                <FormGroup
                                                    row
                                                    sx={{
                                                        width: {xs: '100%', sm: '100%'},
                                                    }}
                                                >
                                                    <StyledDateTimePickerFormControlLabel
                                                        sx={{
                                                            pointerEvents: 'none',
                                                            width: {xs: '100%', sm: '100%'},
                                                            mr: {xs: 0, sm: 0},
                                                            ml: {xs: 0, sm: 0},
                                                            borderRadius: '0.25em',
                                                        }}
                                                        control={
                                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='nb'>
                                                                <MobileDateTimePicker
                                                                    disablePast
                                                                    label={'Dato & Tid'}
                                                                    mask='mm'
                                                                    ampm={false}
                                                                    value={meetingTime}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position='start'>
                                                                                <IconButton sx={{p: '10px'}} aria-label='marker'>
                                                                                    <i className='fa fa-calendar-alt'></i>{' '}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    onChange={time => {
                                                                        if (time) {
                                                                            onLocalChangeDateTime(dayjs(new Date(time)).toISOString())
                                                                            onChange(dayjs(new Date(time)).toISOString())
                                                                        }
                                                                    }}
                                                                    renderInput={props => (
                                                                        <TextField
                                                                            {...props}
                                                                            {...register('meeting.time')}
                                                                            error={!!errors?.meeting?.time}
                                                                            helperText={errors?.meeting?.time?.message ?? ''}
                                                                            value={new Date(meetingTime)}
                                                                            sx={{
                                                                                pointerEvents: 'auto',
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        }
                                                        value={value}
                                                        label={''}
                                                    />
                                                </FormGroup>
                                            )}
                                        />
                                    </Section>
                                    <Section>
                                        <SmallSection>
                                            {course?.learningGoal && (
                                                <>
                                                    <LearningGoalContainer>
                                                        <a href={'#'} onClick={() => setLearningGoalsDialogOpen(true)}>
                                                            Se læringsmålene for kurset
                                                        </a>
                                                    </LearningGoalContainer>
                                                </>
                                            )}
                                            {course?.approvedPoints >= 0 && (course?.type === 0 || course?.type === 1) && (
                                                <TextHeading>
                                                    {course?.type === 0
                                                        ? 'Valgfrie poeng'
                                                        : course?.type === 1
                                                        ? 'Klinisk emnekurs poeng'
                                                        : ''}
                                                    : {course.approvedPoints}
                                                </TextHeading>
                                            )}
                                            {course?.isApprovedForRefund && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: {xs: 'space-between', sm: 'start'},
                                                        alignItems: 'baseline',
                                                    }}
                                                >
                                                    <TextHeading>Kurset er godkjent for refusjon</TextHeading>
                                                    <StyledLink>
                                                        <a href={'https://www.skilnet.no/refusjon/'} target={'_blank'}>
                                                            {' '}
                                                            LES MER
                                                        </a>
                                                    </StyledLink>
                                                </Box>
                                            )}
                                        </SmallSection>
                                        <SmallSection>
                                            {!hasValidInvoiceDetails && (
                                                <>
                                                    <TextHeading>Bindende påmelding</TextHeading>
                                                    <TextHeading>
                                                        Pris per lege:{' '}
                                                        {course?.price === 0 || !course?.price ? 'GRATIS' : course?.price + ' kr'}
                                                    </TextHeading>
                                                    {userCourse && course?.price !== userCourse?.price && userShouldPay && (
                                                        <TextHeading>Din pris: {userCourse?.price} kr</TextHeading>
                                                    )}
                                                    {course?.price !== userCourse?.price && course?.price !== 0 && !userShouldPay && (
                                                        <TextHeading>Din pris: GRATIS</TextHeading>
                                                    )}
                                                </>
                                            )}
                                            {hasValidInvoiceDetails && (
                                                <>
                                                    <TextHeading>
                                                        Du godkjent kursvilkårene {/* @ts-expect-error */}
                                                        {new Date(invoice?.invoiceDetailsConfirmedAt).toLocaleDateString('nb-NO', {
                                                            year: 'numeric',
                                                            month: 'numeric',
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                        })}
                                                    </TextHeading>
                                                </>
                                            )}
                                            {!hasValidInvoiceDetails && (
                                                <>
                                                    <Controller
                                                        name={'tosAccepted'}
                                                        control={control}
                                                        render={({field: {onChange}, fieldState: {error}}) => (
                                                            <>
                                                                <FormGroup sx={{marginTop: '1em'}} row>
                                                                    <FormControlLabel
                                                                        sx={{
                                                                            mb: '0em',
                                                                            ml: '0em',
                                                                            border: errors?.tosAccepted ? 'red solid 1px' : 'none',
                                                                            borderRadius: '0.25em',
                                                                            padding: '0.5em 1em 0.5em 0',
                                                                        }}
                                                                        control={
                                                                            <Checkbox
                                                                                name={'tosAccepted'}
                                                                                required
                                                                                hidden={true}
                                                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                                                            />
                                                                        }
                                                                        onChange={(e, checked) => {
                                                                            onChange(checked)
                                                                            setTosAccepted(checked)
                                                                        }}
                                                                        disabled={false}
                                                                        value={tosAccepted}
                                                                        label={
                                                                            <>
                                                                                Lest og godtatt{' '}
                                                                                <a
                                                                                    href={'#'}
                                                                                    onClick={() => setTermsOfServiceDialogOpen(true)}
                                                                                >
                                                                                    vilkår
                                                                                </a>{' '}
                                                                            </>
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                                <FormHelperText style={{color: '#ab3e36'}}>
                                                                    {error?.message ?? ''}
                                                                </FormHelperText>
                                                            </>
                                                        )}
                                                    />
                                                </>
                                            )}
                                        </SmallSection>
                                    </Section>
                                    {isReadyToShowInvoiceDetails && (
                                        <InvoiceDetails
                                            register={register}
                                            errors={errors}
                                            control={control}
                                            unregister={unregister}
                                            trigger={trigger}
                                            setValue={setValue}
                                            clearErrors={clearErrors}
                                            setError={setError}
                                            tosAccepted={tosAccepted}
                                            invoice={invoice}
                                            isSearchingOrgNr={isSearchingOrgNr}
                                            setIsSearchingOrgNr={setIsSearchingOrgNr}
                                            user={user}
                                            isNefleSignup={false}
                                        />
                                    )}
                                </>
                            )}
                        </Box>
                    </>
                )}
                {!readyToShow && (
                    <>
                        <SkeletonTitleContainer>
                            <Skeleton variant='text' animation='wave' sx={{width: '50%'}} />
                        </SkeletonTitleContainer>
                        <Skeleton variant='rectangular' animation='wave' sx={{height: '2em', width: '50%', mt: 1}} />
                        <Skeleton variant='rectangular' animation='wave' sx={{height: '2em', width: '100%', mt: 1}} />
                        <Grid width={'100%'} sx={{mt: 1}}>
                            <StyledGrid
                                display={'flex'}
                                width={'100%'}
                                xs={12}
                                sm={12}
                                md={12}
                                item
                                sx={{justifyContent: {xs: 'center', sm: 'initial'}}}
                            >
                                <Skeleton variant='rectangular' animation='wave' sx={{height: '20em', width: '50%', mr: 2}} />
                                <Skeleton variant='rectangular' animation='wave' sx={{height: '20em', width: '50%'}} />
                            </StyledGrid>
                        </Grid>
                        <Skeleton variant='rectangular' animation='wave' sx={{height: '2em', width: '25%', mt: 1}} />
                        <Skeleton variant='rectangular' animation='wave' sx={{height: '2em', width: '100%', mt: 1}} />
                    </>
                )}
                {termsOfServiceDialogOpen && (
                    <TermsOfServiceDialog isOpen={termsOfServiceDialogOpen} onClose={() => setTermsOfServiceDialogOpen(false)} />
                )}
                {learningGoalsDialogOpen && course?.learningGoal && (
                    <LearningGoalsDialog
                        isOpen={learningGoalsDialogOpen}
                        onClose={() => setLearningGoalsDialogOpen(false)}
                        learningGoal={course.learningGoal}
                    />
                )}
                {selectedGroup && readyToShow && (
                    <Button type={'submit'} size={'large'} fullWidth disabled={!isSubmittable || isSubmitting}>
                        Inviter gruppen til {shortTitleClean} kurset&nbsp;&nbsp;
                        {isSubmitting && <CircularProgress color='inherit' size={'1.5em'} />}
                    </Button>
                )}
                {isSubmitting && (
                    <BackdropComponent isOpen={isSubmitting || isAddingUser || (!canSignUpToCourse && !!course)} status={'Behandler...'} />
                )}
                {isAddingUser && (
                    <BackdropComponent
                        isOpen={isSubmitting || isAddingUser || (!canSignUpToCourse && !!course)}
                        status={'Legger til bruker...'}
                    />
                )}
                {!canSignUpToCourse && course && (
                    <BackdropComponent isOpen={isSubmitting || isAddingUser || (!canSignUpToCourse && !!course)} status={'Vent litt...'} />
                )}
            </form>
        </PurePage>
    )
}
