import {Card, CardContent, CardHeader, Typography} from '@mui/material'
import LinearProgressWithPercentLabel from '../../Components/LinearProgressWithPercentLabel'
import React from 'react'

const GoalCard = ({
    title,
    subheader,
    description,
    percentage = null,
    secondDescription = '',
    secondPercentage = null,
    thirdDescription = '',
    thirdPercentage = null,
    fourthDescription = '',
    fourthPercentage = null,
    fifthDescription = '',
    fifthPercentage = null,
}) => {
    return (
        <Card elevation={3}>
            <CardHeader
                titleTypographyProps={{variant: 'h3'}}
                title={title}
                subheaderTypographyProps={{variant: 'body1'}}
                subheader={subheader}
            />
            <CardContent>
                <Typography variant='body2'>{description}</Typography>
                {percentage && <LinearProgressWithPercentLabel percentage={percentage} cardSx={{mt: 2}} />}
            </CardContent>
            {secondDescription && (
                <CardContent>
                    <Typography variant='body2'>{secondDescription}</Typography>
                    {secondPercentage && <LinearProgressWithPercentLabel percentage={secondPercentage} cardSx={{mt: 2}} />}
                </CardContent>
            )}
            {thirdDescription && (
                <CardContent>
                    <Typography variant='body2'>{thirdDescription}</Typography>
                    {thirdPercentage && <LinearProgressWithPercentLabel percentage={thirdPercentage} cardSx={{mt: 2}} />}
                </CardContent>
            )}
            {fourthDescription && (
                <CardContent>
                    <Typography variant='body2'>{fourthDescription}</Typography>
                    {fourthPercentage && <LinearProgressWithPercentLabel percentage={fourthPercentage} cardSx={{mt: 2}} />}
                </CardContent>
            )}
            {fifthDescription && (
                <CardContent>
                    <Typography variant='body2'>{fifthDescription}</Typography>
                    {fifthPercentage && <LinearProgressWithPercentLabel percentage={fifthPercentage} cardSx={{mt: 2}} />}
                </CardContent>
            )}
        </Card>
    )
}

export default GoalCard
