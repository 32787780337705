import * as React from 'react'
import Button from '../Components/Button/Button'
import useEntity from '../Hooks/useEntity'
import {useParams} from 'react-router'
import Panel from '../Components/Panel'
import Participants, {ParticipantsType} from './Components/Participants'
import {jsonFetch} from '../Components/jsonFetch'
import Modal from '../Components/Modal'
import {DATE_FORMAT} from '../State/utils/constants'
import format from 'date-fns/format'
import {Page} from '../Components/Page'
import {SkilQueryResponseType, useQueryInvalidate, useSkilMutation, useSkilQuery} from '../Utilities/QueryClient'
import {toast} from 'react-toastify'
import {components} from '../Generated/eportal'
import useUser from '../Utilities/useUser'
import DateTimeField from '../Components/Fields/DateTimeField'
import TextField from '../Components/Fields/TextField'
import SelectField from '../Components/Fields/SelectField'
import {useQuery} from 'react-query'
import {handleErrorWithToast} from '../Utilities/errorHandlers'
import {useEffect, useCallback} from 'react'
import LoadingComponent from '../Components/LoadingComponent'

type CreateSeminarSubtype = Pick<components['schemas']['Partnership.CreateSeminarDTO'], 'meetings'>

type SeminarType = SkilQueryResponseType<'getCourseItem'>

export default function CreateSeminar() {
    const user = useUser()
    const {partnershipId} = useParams()
    const [seminarTemplate, setSeminarTemplate] = React.useState<number | undefined>(undefined)
    const [seminarShortTitle, setSeminarShortTitle] = React.useState<string | undefined>(undefined)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [isListenerAdded, setIsListenerAdded] = React.useState(false)
    const [participants, setParticipants] = React.useState<ParticipantsType>({
        users: [],
        extraUsers: [],
        init: false,
    } as ParticipantsType)
    const partnership = useEntity<'getPartnershipItem'>(partnershipId ? `/api/partnerships/${partnershipId}` : null)
    const {
        data: jobsResponse,
        refetch: refetchJobs,
        isSuccess: isJobsSuccess,
        isLoading: isJobsLoading,
    } = useSkilQuery<'getJobCollection'>(`/api/jobs`, {
        course: seminarTemplate,
        trigger: 'App\\Messages\\Events\\Course\\User\\UserInvitedToCourse',
    })
    const job = jobsResponse?.['hydra:member']?.[0] ?? null
    const {
        data: meetingsResponse,
        refetch: refetchMeetings,
        isSuccess: isMeetingsLoaded,
        isLoading: isMeetingsLoading,
    } = useSkilQuery<'getMeetingTaskCollection'>(`/api/meeting_tasks?course.shortTitle=${seminarShortTitle}`)
    const {
        data: seminarsData,
        refetch: refetchSeminars,
        isSuccess: isSeminarsLoaded,
        isLoading: isSeminarsLoading,
    } = useSkilQuery<'getPartnershipSeminarCollection'>(`/api/partnerships/${partnershipId}/seminars`, null, {enabled: !!partnershipId})
    const {
        data: templates,
        isSuccess: isTemplatesLoaded,
        isLoading: isTemplatesLoading,
    } = useSkilQuery<'getCourseItem'>(`/api/seminar/templates`)

    const seminarTemplates = templates?.['hydra:member'] ?? []
    //const {data, isFetching, refetch} = useSkilQuery<'getUserStatistics'>('/api/users/statistics', {pagination: false})
    const [preview, setPreview] = React.useState<any>(null)
    const invalidate = useQueryInvalidate()
    const [createSeminar, setCreateSeminar] = React.useState<CreateSeminarSubtype>({
        meetings: {},
    })

    const seminars = seminarsData?.['hydra:member'] ?? []

    isSeminarsLoaded &&
        seminars?.sort(function (a, b) {
            if (a.meetingAt < b.meetingAt) {
                return -1
            }
            if (a.meetingAt > b.meetingAt) {
                return 1
            }
            return 0
        })

    const countUsers = participants.users.length
    let countExtra = participants.extraUsers.length
    const count = countUsers + countExtra

    const onUncheckAll = () => {
        setParticipants(p => ({
            ...p,
            users: [],
            extraUsers: [],
        }))
    }

    const onPreview = () => {
        return jsonFetch(`/api/jobs/${job?.id}/preview`, {
            method: 'POST',
            throwOn404: true,
            json: {trigger: 'App\\Messages\\Events\\Course\\User\\UserInvitedToCourse'},
        }).then(setPreview)
    }

    const postSeminar = useSkilMutation<'createPartnershipSeminar'>('post', `/api/partnerships/${partnership.id}/create_seminar`)
    const onSubmit = async () => {
        setIsSubmitting(true)
        try {
            await postSeminar.mutateAsync({
                ...createSeminar,
                // @ts-expect-error
                users: participants.users.map(id => `/api/users/${id}`),
                extraUsers: participants.extraUsers,
                // @ts-expect-error - seminarTemplate will never be undefined on submit
                template: seminarTemplate,
            })
            await refetchSeminars()
            await refetchMeetings()
            await invalidate([user['@id'] + '/seminars'])
            toast('- Seminar opprettet og alle deltakere er invitert', {type: 'success'})
            // redirect using window.location.replace to trigger refresh of the available seminars on the Kommunedashboard
            window.location.replace(`${window.location.protocol}//${window.location.hostname}/dashboard/kommuner/${partnership.id}`)
        } catch (e) {
            handleErrorWithToast(e)
            setIsSubmitting(false)
        }
    }

    const breadcrumbs = [{title: 'Kommunedashboard', to: '/dashboard/kommuner/' + partnership.id}, {title: 'Nytt kvalitetsseminar'}]
    const isLoading = !partnership['@loaded'] || isTemplatesLoading || isJobsLoading || isMeetingsLoading || isSeminarsLoading
    const meetings = meetingsResponse?.['hydra:member'] ?? []
    const allMeetingsPlanned = meetings
        .map(m => createSeminar.meetings[`/api/meeting_tasks/${m.id}`])
        .every(m => m?.task && m?.location && m?.time)
    const missingMeeting = !isMeetingsLoaded || !allMeetingsPlanned

    function beforeUnloadHandler(event) {
        // Recommended
        event.preventDefault()

        // Included for legacy support, e.g. Chrome/Edge < 119
        event.returnValue = true
    }
    // we need to use useCallBack to maintain a reference to the function on every render
    const boundBeforeUnloadHandler = useCallback(beforeUnloadHandler, [])

    // we want to warn the user if they try to leave the page while they have unsaved changes
    useEffect(() => {
        const hasExtraUsers = participants.extraUsers.length > 0
        const hasUsers = participants.users.length > 0
        const hasTemplate = seminarTemplate !== undefined
        const hasMeetings = Object.keys(createSeminar.meetings).length > 0
        if ((hasMeetings || hasTemplate || hasUsers || hasExtraUsers) && !isSubmitting) {
            if (!isListenerAdded) {
                setIsListenerAdded(true)
                window.addEventListener('beforeunload', boundBeforeUnloadHandler)
            }
        } else {
            if (isListenerAdded) {
                setIsListenerAdded(false)
                window.removeEventListener('beforeunload', boundBeforeUnloadHandler)
            }
        }
    }, [createSeminar.meetings, seminarTemplate, participants, isSubmitting])

    if (isLoading) {
        return <LoadingComponent msg={'Laster inn...'} />
    }

    return (
        <Page breadcrumbs={breadcrumbs} variant={'default'}>
            <div className={'list-group'}>
                <Panel variant={'flat'}>
                    <Panel.Body>
                        <SelectField
                            label={'Seminar tema'}
                            id={'template'}
                            value={seminarTemplate}
                            entities={seminarTemplates}
                            onChange={(seminar: SeminarType) => {
                                setSeminarTemplate(seminar.id)
                                setSeminarShortTitle(seminar.shortTitle)
                                refetchJobs().then()
                            }}
                        />
                        <label style={{marginBottom: '40px', display: 'block'}}>
                            Deltakere{' '}
                            <Button variant={'link'} className={'pull-right'} onClick={onUncheckAll} inline>
                                Velg ingen
                            </Button>{' '}
                        </label>
                        <Participants participantId={partnershipId} participants={participants} setParticipants={setParticipants} />
                    </Panel.Body>
                </Panel>
                {!isLoading && (
                    <>
                        <Panel variant={'flat'}>
                            {meetings.map(meeting => {
                                const iri = `/api/meeting_tasks/${meeting.id}`
                                let label = meeting.title + ' tidspunkt'
                                let timeId = 'time' + meeting.id
                                let locationId = 'location' + meeting.id
                                return (
                                    <Panel.Body key={iri}>
                                        <DateTimeField
                                            id={timeId}
                                            key={timeId}
                                            label={label}
                                            placeholder=''
                                            value={createSeminar.meetings[iri]?.time ?? null}
                                            onChange={time =>
                                                setCreateSeminar(seminar => ({
                                                    ...seminar,
                                                    meetings: {
                                                        ...seminar.meetings,
                                                        [iri]: {
                                                            ...(seminar.meetings[iri] ?? {location: '', task: iri}),
                                                            time,
                                                        },
                                                    },
                                                }))
                                            }
                                            required
                                        />
                                        <TextField
                                            id={locationId}
                                            key={locationId}
                                            type='text'
                                            label='Sted'
                                            placeholder=''
                                            required
                                            value={createSeminar.meetings[iri]?.location ?? null}
                                            onChange={location =>
                                                setCreateSeminar(seminar => ({
                                                    ...seminar,
                                                    meetings: {
                                                        ...seminar.meetings,
                                                        [iri]: {
                                                            ...(seminar.meetings[iri] ?? {time: '', task: iri}),
                                                            location,
                                                        },
                                                    },
                                                }))
                                            }
                                        />
                                    </Panel.Body>
                                )
                            })}
                            {isSeminarsLoaded && (
                                <Panel.Footer className={'text-right'}>
                                    {isJobsSuccess && seminarTemplate && (
                                        <Button onClick={onPreview} variant={'default'}>
                                            Forhåndsvis epost
                                        </Button>
                                    )}
                                    <Button
                                        disabled={count === 0 || missingMeeting || !seminarTemplate}
                                        onClick={onSubmit}
                                        variant={'primary'}>
                                        Opprett seminar og inviter valgte ({count}) brukere
                                    </Button>
                                    {preview && (
                                        <Modal
                                            size={'lg'}
                                            onClose={() => setPreview(null)}
                                            title={'Emne: ' + preview.title}
                                            cancelText={'Skjul'}>
                                            <div dangerouslySetInnerHTML={{__html: preview.content}} />
                                        </Modal>
                                    )}
                                </Panel.Footer>
                            )}
                            {isSeminarsLoaded && seminars.length > 0 && (
                                <Panel.Body>
                                    <div className={'table-responsive'}>
                                        <table className={'table'}>
                                            <thead>
                                                <tr>
                                                    <th colSpan={5}>Planlagte seminar</th>
                                                </tr>
                                                <tr>
                                                    <th>Dato</th>
                                                    <th>Sted</th>
                                                    <th>Invitert</th>
                                                    <th>Påmeldt</th>
                                                    <th>Påmelding</th>
                                                    <th />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {seminars.map(seminar => {
                                                    const meetingAt = seminar.meetingAt ? new Date(seminar.meetingAt) : null

                                                    return (
                                                        <tr key={seminar.meeting}>
                                                            <td>{meetingAt ? format(meetingAt, DATE_FORMAT) : null}</td>
                                                            <th>{seminar.place}</th>
                                                            <td>{seminar.invited}</td>
                                                            <td>{seminar.signups}</td>
                                                            <th>
                                                                <Button
                                                                    to={`/dashboard/kommuner/${partnershipId}/seminars/${seminar.meeting}`}
                                                                    inline
                                                                    variant={'link'}>
                                                                    Detaljer
                                                                </Button>
                                                            </th>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Panel.Body>
                            )}
                        </Panel>
                    </>
                )}
            </div>
        </Page>
    )
}
