import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import { useThemeProps } from '@mui/material/styles';
import { DATA_GRID_PRO_PROPS_DEFAULT_VALUES, DATA_GRID_DEFAULT_SLOTS_COMPONENTS, GRID_DEFAULT_LOCALE_TEXT } from '@mui/x-data-grid-pro';
import { GRID_AGGREGATION_FUNCTIONS } from '../hooks/features/aggregation';
/**
 * The default values of `DataGridPremiumPropsWithDefaultValue` to inject in the props of DataGridPremium.
 */

export const DATA_GRID_PREMIUM_PROPS_DEFAULT_VALUES = _extends({}, DATA_GRID_PRO_PROPS_DEFAULT_VALUES, {
  disableAggregation: false,
  disableRowGrouping: false,
  rowGroupingColumnMode: 'single',
  aggregationFunctions: GRID_AGGREGATION_FUNCTIONS,
  aggregationRowsScope: 'filtered',
  getAggregationPosition: groupNode => groupNode == null ? 'footer' : 'inline'
});
export const useDataGridPremiumProps = inProps => {
  const themedProps = useThemeProps({
    props: inProps,
    name: 'MuiDataGrid'
  });
  const localeText = React.useMemo(() => _extends({}, GRID_DEFAULT_LOCALE_TEXT, themedProps.localeText), [themedProps.localeText]);
  const components = React.useMemo(() => {
    const overrides = themedProps.components;

    if (!overrides) {
      return _extends({}, DATA_GRID_DEFAULT_SLOTS_COMPONENTS);
    }

    const mergedComponents = {};
    Object.entries(DATA_GRID_DEFAULT_SLOTS_COMPONENTS).forEach(([key, defaultComponent]) => {
      mergedComponents[key] = overrides[key] === undefined ? defaultComponent : overrides[key];
    });
    return mergedComponents;
  }, [themedProps.components]);
  return React.useMemo(() => {
    var _themedProps$experime;

    return _extends({}, DATA_GRID_PREMIUM_PROPS_DEFAULT_VALUES, themedProps, {
      disableAggregation: themedProps.disableAggregation || !((_themedProps$experime = themedProps.experimentalFeatures) != null && _themedProps$experime.aggregation),
      localeText,
      components,
      signature: 'DataGridPremium'
    });
  }, [themedProps, localeText, components]);
};