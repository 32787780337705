import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["shouldDisableDate"];
import { useValidation, validateDate } from '@mui/x-date-pickers/internals';
import { isRangeValid, parseRangeInputValue } from '../../utils/date-utils';
export const validateDateRange = ({
  props,
  value,
  adapter
}) => {
  const [start, end] = value; // for partial input

  if (start === null || end === null) {
    return [null, null];
  }

  const {
    shouldDisableDate
  } = props,
        otherProps = _objectWithoutPropertiesLoose(props, _excluded);

  const dateValidations = [validateDate({
    adapter,
    value: start,
    props: _extends({}, otherProps, {
      shouldDisableDate: day => !!(shouldDisableDate != null && shouldDisableDate(day, 'start'))
    })
  }), validateDate({
    adapter,
    value: end,
    props: _extends({}, otherProps, {
      shouldDisableDate: day => !!(shouldDisableDate != null && shouldDisableDate(day, 'end'))
    })
  })];

  if (dateValidations[0] || dateValidations[1]) {
    return dateValidations;
  }

  if (!isRangeValid(adapter.utils, parseRangeInputValue(adapter.utils, value))) {
    return ['invalidRange', 'invalidRange'];
  }

  return [null, null];
};
export const isSameDateRangeError = (a, b) => b !== null && a[1] === b[1] && a[0] === b[0];
export const useDateRangeValidation = props => {
  return useValidation(props, validateDateRange, isSameDateRangeError);
};