import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import Button from '../Components/Button/Button'
import {useQuery, useQueryClient} from 'react-query'
import LoadingComponent from '../Components/LoadingComponent'
import ErrorComponent from '../Components/ErrorComponent'

const ContainerDiv = styled.div`
    .navbar-form {
        text-align: center;

        input {
            min-width: 50%;
        }
    }
    @media (max-width: 600px) {
        .navbar-form {
            margin: 0 -15px;
        }
    }

    .loading-component {
        color: #777;
    }
`

const ResultsDiv = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 50px;
    background: #052a30;
    color: #fefefe;
    overflow-y: scroll;
    transition: all 0.1s ease;
`

type SearchItem = {
    text: string
    url: string
    icon: string
    id: number
    '@id': string
    eid: string
    type: string
}

type ApiResponse = {
    results: SearchItem[]
}
export default function Search() {
    const [search, setSearch] = useState('')
    const queryClient = useQueryClient()
    const ref = useRef<any>()
    const {
        data,
        isSuccess,
        isLoading,
        isError,
        // @ts-expect-error
    } = useQuery(`/dashboard/api/search?term=${search}&filter=users,groups,offices,courses,partnerships`, {
        enabled: search.length >= 3,
        select: (data: ApiResponse, err) => {
            if (err) return

            return (
                data?.results?.reduce((carry, item) => {
                    if (!carry[item.type]) carry[item.type] = []

                    carry[item.type].push(item)
                    return carry
                }, {}) ?? {}
            )
        },
    })
    // @ts-expect-error Untyped api results
    const results: {[type: string]: SearchItem[]} = data

    useEffect(() => {
        queryClient.invalidateQueries()
    }, [])

    useEffect(() => {
        const onHotkey = e => {
            const cmd = (e.ctrlKey ? 1 : 0) | (e.altKey ? 2 : 0) | (e.shiftKey ? 4 : 0) | (e.metaKey ? 8 : 0)

            if ((cmd === 1 || cmd === 8 || cmd === 5 || cmd === 12) && e.keyCode === 70) {
                e.preventDefault()
                ref.current?.focus()
            }
        }

        document.addEventListener('keyup', onHotkey, false)

        return () => {
            document.removeEventListener('keyup', onHotkey)
        }
    }, [])

    const onHide = () => {
        setSearch('')
    }

    return (
        <ContainerDiv>
            <div className='navbar-form form-group'>
                <input
                    className={'form-control'}
                    // ts-ignore
                    ref={ref}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder={'Søk... (Ctrl+Shift+F)'}
                />
            </div>
            <ResultsDiv style={{height: search.length < 3 ? 0 : 'calc(99vh - 50px)'}}>
                <Button onClick={onHide} block variant={'primary'} inline>
                    <i className={'fa fa-times'} /> Skjul
                </Button>
                {isLoading && <LoadingComponent msg={'Søker...'} />}
                {isError && <ErrorComponent style={{color: 'white'}} msg={'Noe gikk galt'} />}
                {isSuccess &&
                    results &&
                    Object.entries(results).map(([key, list]) => (
                        <div key={key}>
                            <h4 style={{color: 'white'}}>{key}</h4>
                            {list.map(r => (
                                <a key={r['@id']} className={'btn btn-app btn-primary'} style={{color: 'white'}} href={r.url}>
                                    <i className={`fa fa-${r.icon}`} />
                                    {r.text}
                                </a>
                            ))}
                        </div>
                    ))}
                <br />
                <br />
                <Button onClick={onHide} block variant={'primary'} inline>
                    <i className={'fa fa-times'} /> Skjul
                </Button>
            </ResultsDiv>
        </ContainerDiv>
    )
}
