import React, {ReactNode} from 'react'
import 'dayjs/locale/nb'
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import SkilLogoTopWhite from './skil-logo-top-white.png'
import SkilLogoWhite from './skil-logo-white.png'

const BackdropContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    max-height: 100%;
`

const BackdropFooter = styled.div`
    bottom: 10px;
    margin: 2em;
    margin-bottom: 0;
    display: block;
`
const BackdropStatus = styled.p`
    font-size: 3em;
`
const BackdropSlogan = styled.p`
    font-size: 1.2em;
    text-align: center;
`
const CircularProgressContainer = styled.div`
    display: flex;
    align-items: end;
    justify-content: center;
    margin-bottom: 3em;
`

const StyledLogo = styled.img`
    width: 5em;
`
const CompanyNameContainer = styled.div`
    display: flex;
    justify-content: center;
`

const BackdropChildrenContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 300px); /* Adjust as needed */
    overflow-y: auto;

    /* Hide scrollbar for Chrome, Safari, and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge, and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`

const ParentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .scroll-indicator {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        z-index: 10;
    }

    .scroll-indicator i {
        font-size: 2rem;
        margin: 10px;
        animation: blink 1.5s infinite;
    }

    .scroll-indicator i.visible {
        display: block; /* Make visible when needed */
    }

    .scroll-indicator i.hidden {
        display: none;
    }

    @keyframes blink {
        0%,
        100% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
    }
`

export const BackdropComponent = ({
    isOpen,
    status,
    showSpinner = true,
    children,
}: {
    isOpen: boolean
    status: string
    showSpinner?: boolean
    children?: ReactNode
}) => {
    const [canScrollUp, setCanScrollUp] = React.useState(false)
    const [canScrollDown, setCanScrollDown] = React.useState(false)
    const circularProgressContainer = document.getElementById('circularProgressContainer')
    const backdropFooter = document.getElementById('backdropFooter')
    const newsContainerRef = React.useRef(null)
    const checkScroll = () => {
        if (newsContainerRef.current) {
            const {scrollTop, scrollHeight, clientHeight} = newsContainerRef.current
            setCanScrollUp(scrollTop > 0)
            setCanScrollDown(scrollTop + clientHeight < scrollHeight)
        }
    }

    React.useEffect(() => {
        if (canScrollUp) {
            circularProgressContainer?.style.setProperty('margin-bottom', '0')
        } else {
            circularProgressContainer?.style.setProperty('margin-bottom', '3em')
        }
        if (canScrollDown) {
            backdropFooter?.style.setProperty('margin-top', '0')
        } else {
            backdropFooter?.style.setProperty('margin-top', '2em')
        }
    }, [canScrollUp, canScrollDown])

    React.useEffect(() => {
        const newsContainer = newsContainerRef.current
        // @ts-expect-error
        newsContainer?.addEventListener('scroll', checkScroll)
        checkScroll() // Initial check
        return () => {
            // @ts-expect-error
            newsContainer.removeEventListener('scroll', checkScroll)
        }
    }, [])

    return (
        <>
            <Backdrop sx={{color: '#fff', backgroundColor: 'rgba(0,0,0,0.85)', zIndex: theme => theme.zIndex.drawer + 1}} open={isOpen}>
                <BackdropContainer>
                    <div>
                        <StyledLogo src={SkilLogoTopWhite} title='SKIL Logo' id='skil-logo' />
                    </div>
                    <ParentContainer>
                        <CircularProgressContainer id={'circularProgressContainer'}>
                            {showSpinner && <CircularProgress color='inherit' size={'4em'} />}
                            &nbsp;&nbsp;
                            <BackdropStatus>{status}</BackdropStatus>
                        </CircularProgressContainer>
                        <div className={`scroll-indicator ${canScrollUp ? 'visible' : 'hidden'}`}>
                            <i className='fa fa-arrow-up'></i>
                        </div>
                        <BackdropChildrenContainer ref={newsContainerRef}>{children}</BackdropChildrenContainer>
                        <div className={`scroll-indicator ${canScrollDown ? 'visible' : 'hidden'}`}>
                            <i className='fa fa-arrow-down'></i>
                        </div>
                    </ParentContainer>
                    <BackdropFooter id={'backdropFooter'}>
                        <div>
                            <BackdropSlogan>Med kunnskap og engasjement skaper vi kvalitet for alle.</BackdropSlogan>
                        </div>
                        <CompanyNameContainer>
                            <StyledLogo src={SkilLogoWhite} title='SKIL Logo' id='skil-logo-light' />
                        </CompanyNameContainer>
                    </BackdropFooter>
                </BackdropContainer>
            </Backdrop>
        </>
    )
}
