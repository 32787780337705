import * as React from 'react'
import {Box} from '../../Components/Panel'
import DataGrid, {InitModel} from '../../Components/DataGrid/DataGrid'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import {useSkilMutation, useSkilQuery} from '../../Utilities/QueryClient'
import {GridCellEditCommitParams, GridCellParams, GridColDef, GridSortItem, GridValueGetterParams} from '@mui/x-data-grid-premium'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import dateColumnDef from '../../Components/DataGrid/columnDefs/dateColumnDef'
import arrayOfDatesColumnDef from '../../Components/DataGrid/columnDefs/arrayOfDatesColumnDef'
import Button from '../../Components/Button/Button'
import useUser from '../../Utilities/useUser'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'
import {toast} from 'react-toastify'
import {CreatePartnershipDialog} from '../Components/CreatePartnershipDialog'
import SpecialActions from '../../Components/DataGrid/SpecialActions'
import {SpecialActionProps} from '../../Components/DataGrid/SpecialAction'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import {useQueryInvalidate} from '../../Utilities/QueryClient'
import {useNavigate} from 'react-router'
import AddIcon from '@mui/icons-material/Add'
import {FilterList as FilterListIcon} from '@mui/icons-material'
import {useState} from 'react'
import selectColumnDef from '../../Components/DataGrid/columnDefs/selectColumnDef'
import {COURSE_TYPES} from './Courses'
import CheckboxField from '../../Components/Fields/CheckboxField'
import LoadingComponent from '../../Components/LoadingComponent'

const renderNameCell = params => {
    if (!params.value) return ''

    return (
        <Button variant='text' to={`/dashboard/SKIL/samarbeid/${params.row.partnership.id}`}>
            {params.value}
        </Button>
    )
}
const renderJiraCell = params => {
    if (!params.row.signupJiraLink) return ''
    return (
        <Button variant='text' target={'_blank'} href={`${params.row.signupJiraLink}`}>
            Link
        </Button>
    )
}

const booleanValueGetter = () => (params: GridValueGetterParams) => {
    const value = params.value ?? false
    if (typeof value === 'boolean') return value
}

const seminarThemeValueGetter = seminarTemplates => (params: GridValueGetterParams) => {
    const seminarTemplate = seminarTemplates.find(seminarTemplate => seminarTemplate.value === params.value)
    return seminarTemplate?.label ?? ''
}

const columnVisibilityModel = {
    id: false,
    'partnership.id': false,
    createdAt: true,
    name: true,
    seminarTheme: true,
    informationMeetingAt: true,
    plannedSeminarAt: true,
    signupIiraLink: true,
    processed: true,
}

const PartnershipSignups = () => {
    const [sortModel, setSortModel] = React.useState<GridSortItem[]>([])
    const {
        data: partnershipSignupsData,
        isFetching: isFetchingPartnershipSignups,
        refetch: refetchPartnershipSignups,
    } = useSkilQuery<'getPartnershipSignupCollection'>('/api/partnership_signups')
    console.log('partnershipSignupsData', partnershipSignupsData)
    const updatePartnershipSignupMutation = useSkilMutation<'patchPartnershipSignupItem'>('PATCH', `/api/partnership_signups/${null}`)
    const {data: templates} = useSkilQuery<'getCourseItem'>(`/api/seminar/templates`)
    const seminarTemplates =
        templates?.['hydra:member'].map(template => {
            return {value: template['@id'], label: template.title}
        }) ?? []
    console.log('seminarTemplates', seminarTemplates)
    const [showCreatePartnership, setShowCreatePartnership] = React.useState<boolean>(false)
    const [initModel, setInitModel] = useState<InitModel>({columnVisibilityModel, sort: [{field: 'createdAt', sort: 'desc'}]})

    const partnershipSignups = partnershipSignupsData?.['hydra:member'] ?? []

    const actions: SpecialActionProps[] = [
        {
            label: 'Ubehandlet',
            variant: 'outlined',
            action: () =>
                setInitModel({
                    columnVisibilityModel: {id: false, 'partnership.id': false},
                    sort: [{field: 'createdAt', sort: 'desc'}],
                    filter: [{columnField: 'processed', operatorValue: 'is', value: 'false'}],
                }),
            icon: {
                icon: <FilterListIcon />,
                position: 'start',
            },
        },
        {
            label: 'Behandlet',
            variant: 'outlined',
            action: () =>
                setInitModel({
                    columnVisibilityModel: {id: false, 'partnership.id': false},
                    sort: [{field: 'createdAt', sort: 'desc'}],
                    filter: [{columnField: 'processed', operatorValue: 'is', value: 'true'}],
                }),
            icon: {
                icon: <FilterListIcon />,
                position: 'start',
            },
        },
        {
            label: 'Alle',
            variant: 'outlined',
            action: () =>
                setInitModel({
                    columnVisibilityModel: {id: false, 'partnership.id': false},
                    sort: [{field: 'createdAt', sort: 'desc'}],
                    filter: [],
                }),
            icon: {
                icon: <FilterListIcon />,
                position: 'start',
            },
        },
    ]

    const onToggleProcessed = async (params: GridCellEditCommitParams) => {
        try {
            await updatePartnershipSignupMutation.mutateAsync({
                // Row is undefined on params, but does exist :/
                // @ts-expect-error
                '@overridePath': `/api/partnership_signups/${params.row.id}`,
                // @ts-expect-error
                processed: params.value,
            })
            await refetchPartnershipSignups()
            toast('Endring av behandlet status ble lagret', {type: 'success'})
        } catch (e) {
            handleErrorWithToast(e)
        } finally {
            refetchPartnershipSignups()
                .then(() => {})
                .catch(() => {})
        }
    }

    const handleSortModelChange = model => {
        setSortModel(model)
    }

    const columns: GridColDef[] = [
        columnDef({field: 'id', headerName: 'ID', type: 'number'}),
        columnDef({field: 'partnership.id', headerName: 'Samarbeids ID', type: 'number'}),
        dateColumnDef({field: 'createdAt', headerName: 'Påmeldingsdato'}),
        columnDef({field: 'name', headerName: 'Navn', renderCell: renderNameCell}),
        columnDef({
            field: 'seminarTheme',
            headerName: 'Seminar tema',
            type: 'number',
            valueGetter: seminarThemeValueGetter(seminarTemplates),
        }),
        dateColumnDef({field: 'informationMeetingAt', headerName: 'Informasjonsmøte'}),
        dateColumnDef({field: 'plannedSeminarAt', headerName: 'Planlagt seminar'}),
        columnDef({field: 'signupIiraLink', headerName: 'Jira lenke', type: 'string', renderCell: renderJiraCell}),
        columnDef(
            {field: 'processed', headerName: 'Behandlet status', type: 'boolean', valueGetter: booleanValueGetter()},
            {onCellEditCommit: onToggleProcessed}
        ),
    ]

    return (
        <Box>
            <DataGridWrapper rows={partnershipSignups.length}>
                <DataGrid
                    id={'participants'}
                    loading={isFetchingPartnershipSignups}
                    rows={partnershipSignups}
                    initModel={initModel}
                    columns={columns}
                    disableSelectionOnClick
                    onSortModelChange={handleSortModelChange}
                    toolbarButtons={<SpecialActions actions={actions} />}
                />
            </DataGridWrapper>
        </Box>
    )
}

export default PartnershipSignups
