import React, {useState, useEffect} from 'react'
import Button from '../Components/Button/Button'
import useEntity from '../Hooks/useEntity'
import Collapse from '../Components/Collapse'
import useEntities from '../Hooks/useEntities'
import {useParams} from 'react-router-dom'
import {useNavigate} from 'react-router'
import RichTextField from '../Components/Fields/RichTextField'
import Participants, {ParticipantsType} from './Components/Participants'
import Panel from '../Components/Panel'
import LoadingComponent from '../Components/LoadingComponent'
import {Page} from '../Components/Page'
import useUser from '../Utilities/useUser'
import {useSkilMutation} from '../Utilities/QueryClient'
import {toast} from 'react-toastify'
import {handleErrorWithToast} from '../Utilities/errorHandlers'
import TextField from '../Components/Fields/TextField'
import CheckboxField from '../Components/Fields/CheckboxField'
import SimpleSelectField from '../Components/Fields/SimpleSelectField'

const EMAIL_TEMPLATE = name => `
<h4>Kjære fastlege!</h4>

<p>Kommunen ønsker å invitere deg til å gjennomføre SKIL kvalitetspakker!</p>
<p>En SKIL kvalitetspakke teller som et klinisk emnekurs og organiseres som tre gruppemøter hvor dere planlegger og følger opp kvaliteten i egen praksis knyttet til et bestemt tema. Før møtene tas e-læringskurs med oppdatert kunnskap. Mange leger har hittil vært fornøyde med SKILs kurs.</p>
<p><strong>SKIL tilbyr nå kvalitetspakker innenfor følgende tema:</strong></p>

<ul>
<li><a href="https://www.skilnet.no/pakker/legemiddelgjennomgang-lmg/" target="_blank">Legemiddelgjennomgang</a></li>
<li><a href="https://www.skilnet.no/pakker/riktigere-antibiotikabruk-ikommunene-rak/" target="_blank">Riktigere antibiotikaforskrivning</a></li>
<li><a href="https://www.skilnet.no/pasientforlop-sarbare-grupper-psg/">Gode pasientforløp for sårbare grupper</a></li>
<li><a href="https://www.skilnet.no/gjor-kloke-valg-klok/" target="_blank">Kloke Valg</a></li>
<li><a href="https://www.skilnet.no/pakker/sykmeldingsarbeid-for-leger-sfl/" target="_blank">Sykmeldingsarbeid</a></li>
<li><a href="https://www.skilnet.no/pakker/smittevern-smv/" target="_blank">Smittevern</a></li>
</ul>

<p><strong>Dekning av fastlegers utgifter</strong></p>
<p>Legeforeningens fond for kvalitetsforbedring og pasientsikkerhet dekker både kursavgift og praksiskompensasjon for leger som gjennomfører kurs i kvalitetsarbeid, les mer her (lenke til nyhetssak fra Kvalitetsfondet).</p>
<p>Dersom dere ønsker, deltar vi gjerne fra kommunen på ett av de tre møtene for å planlegge kvalitetsarbeidet sammen.</p>
<p><strong>Hvordan bli med?</strong></p>
<p>For å bli med gjør dere som følger:</p>

<ul>
    <li>Du velger selv hvem du ønsker å være på gruppe med. Dette kan enten være smågruppen, legekontoret eller en tidligere SKIL-gruppe.</li>
    <li>Gruppeleder går inn på skilnet.no/pakker og melder på sin gruppe. Nødvendig informasjon er navn på deltakerne og epost (om SKILs system ikke har epost-adressen allerede).</li>
    <li>Det settes opp dato for det første gruppemøtet i påmeldingsskjemaet.</li>
    <li>Alle vil motta en e-post med påmeldingsinformasjon hvor de må godta vilkår innen 14 dager.</li>
    <li>Kursavgiftene betales av den enkelte og vil refunderes når kurset er fullført.</li>
</ul>
<p>Ved kvalitetspakkene i legemiddelgjennomgang, riktigere antibiotika, gode pasientforløp og kloke valg er det anbefalt å benytte programvaren Medrave. Vi vil informere om dette i en egen korrespondanse dersom aktuelt.</p>

<p><br></p>

<p>Hilsen ${name}</p>
`

export default function Communication() {
    const user = useUser()
    const {partnershipId} = useParams()
    const [message, setMessage] = useState(EMAIL_TEMPLATE(user.name))
    const [title, setTitle] = useState('Praksiskompensasjon for å gjennomføre kvalitetskurs')
    const partnership = useEntity<'getPartnershipItem'>(partnershipId ? `/api/partnerships/${partnershipId}` : null)
    const [participants, setParticipants] = useState<ParticipantsType>({users: [], extraUsers: [], init: false})
    const [messageConfirmed, setMessageConfirmed] = useState(false)
    const sendMessageMutation = useSkilMutation<'sendPartnershipMessage'>('POST', '/api/partnerships/' + partnership.id + '/send_message')

    const onSubmit = async () => {
        try {
            await sendMessageMutation.mutateAsync({
                message,
                title,
                // @ts-expect-error
                users: participants.users.map(id => `/api/users/${id}`),
                extraUsers: participants.extraUsers,
            })
            toast('Eposten sendt!', {type: 'success'})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    const breadcrumbs = [{title: 'Kommunedashboard', to: '/dashboard/kommuner/' + partnershipId}, {title: 'Kommunikasjon'}]

    const isValidSeminar = true
    const isValidUsers = true

    const formValid = isValidSeminar && isValidUsers

    const selectedDoctors = participants.users.length + participants.extraUsers.length

    return (
        <Page breadcrumbs={breadcrumbs} variant={'default'}>
            <div className={'col-xs-12'}>
                {!partnership['@loaded'] ? (
                    partnershipId ? (
                        <LoadingComponent />
                    ) : null
                ) : (
                    <Collapse expanded={partnership['@loaded']}>
                        <Panel variant={'flat'}>
                            <Panel.Body>
                                <label style={{marginBottom: '20px'}}>Mottakere</label>
                                <Participants participantId={partnershipId} participants={participants} setParticipants={setParticipants} />
                            </Panel.Body>
                        </Panel>

                        <Panel variant={'flat'}>
                            <Panel.Body>
                                {partnership['@loaded'] && (
                                    <Collapse expanded={partnership['@loaded']}>
                                        <TextField id='title' label='Emne' value={title} onChange={setTitle} required />
                                        <RichTextField id='message' label='Melding' value={message} onChange={setMessage} />
                                        <CheckboxField
                                            id='confirmed_messsage'
                                            label={`Jeg bekrefter at informasjonen i e-posten som sendes ut til ${selectedDoctors} leger er korrekt`}
                                            value={messageConfirmed}
                                            defaultChecked={false}
                                            onChange={setMessageConfirmed}
                                            required
                                        />
                                    </Collapse>
                                )}
                            </Panel.Body>
                            <Panel.Footer className={'text-right'}>
                                {!formValid && <div className={'text-danger'}> Skjemaet har feil</div>}
                                <Button disabled={!formValid || !messageConfirmed} onClick={onSubmit} variant={'primary'}>
                                    Send informasjon
                                </Button>
                            </Panel.Footer>
                        </Panel>
                    </Collapse>
                )}
            </div>
        </Page>
    )
}
