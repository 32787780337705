import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import {SxProps} from '@mui/material/styles'
import {useEffect} from 'react'
import {CountiesExport} from './CountiesExport'

type CountyOptionsType = {
    label: string
    id: string
    region: string
    regionId: number
}

type DistrictOptionsType = {
    label: string
    id: string
}

type Props = {
    value: string | null
    valueDistrict?: string | null
    onChange: (
        event,
        county: CountyOptionsType | null,
        iri: string | null,
        district: DistrictOptionsType | null,
        countiesRaw: CountyOptionsType
    ) => any
    required?: boolean
    requiredDistrict?: boolean
    sx?: SxProps
    error?: boolean
    helperText?: string
    errorDistrict?: boolean
    helperTextDistrict?: string
}

export const CountyAutocomplete = ({
    value,
    valueDistrict = null,
    onChange,
    helperText = '',
    error = false,
    errorDistrict = false,
    helperTextDistrict = '',
    required = false,
    requiredDistrict = false,
    sx = undefined,
}: Props) => {
    const [hasLoaded, setHasLoaded] = React.useState(false)
    const countiesRaw = CountiesExport(hasLoaded)

    const counties = countiesRaw
        .map(county => {
            return {label: county.label, id: county.id, region: county.region, regionId: county.regionId}
        })
        .sort(function (a, b) {
            if (a.region < b.region) {
                return -1
            }
            if (a.region > b.region) {
                return 1
            }
            if (a.label < b.label) {
                return -1
            }
            if (a.label > b.label) {
                return 1
            }
            return 0
        })
    const [localCountyValue, setLocalCountyValue] = React.useState<CountyOptionsType | null>(() => {
        return counties.find(county => county.id === value) ?? null
    })

    // @ts-expect-error
    const [localDistrictValue, setLocalDistrictValue] = React.useState<DistrictOptionsType | null>(valueDistrict ?? null)

    const countyDistricts = localCountyValue
        ? countiesRaw
              ?.find(county => county.id === localCountyValue.id)
              ?.districts?.map(district => {
                  let iri = district['@id'] as string
                  return {label: district.name, id: iri}
              })
              .sort(function (a, b) {
                  if (a.label < b.label) {
                      return -1
                  }
                  if (a.label > b.label) {
                      return 1
                  }
                  return 0
              })
        : []

    useEffect(() => {
        if (valueDistrict !== localDistrictValue && valueDistrict !== null) {
            const valueDistrictObj = countyDistricts?.find(district => district.id === valueDistrict)
            if (valueDistrictObj) {
                setLocalDistrictValue(valueDistrictObj)
            }
        }
    }, [valueDistrict])

    const onLocalChangeCounty = (event, newValue: CountyOptionsType | null) => {
        setLocalCountyValue(newValue)
        setLocalDistrictValue(null)
        // @ts-expect-error
        return onChange(event, newValue, newValue?.id ?? null, null, countiesRaw)
    }

    const onLocalChangeDistrict = (event, newValue: DistrictOptionsType | null) => {
        setLocalDistrictValue(newValue)
        // @ts-expect-error
        return onChange(event, localCountyValue, localCountyValue?.id ?? null, newValue ?? null, countiesRaw)
    }

    useEffect(() => {
        // we only want to get the counties once
        if (!hasLoaded && counties?.length > 0) {
            setHasLoaded(true)
        }
    }, [counties])

    return (
        <>
            <Autocomplete<CountyOptionsType>
                sx={sx}
                id={'county'}
                fullWidth
                value={localCountyValue}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={onLocalChangeCounty}
                groupBy={option => option.region}
                // @ts-expect-error Wrong type definition from mui?
                disableClearable={!!required}
                options={counties}
                renderInput={params => <TextField error={error} helperText={helperText} required={required} {...params} label='Kommune' />}
            />
            {countyDistricts && countyDistricts?.length > 0 && (
                <Autocomplete<DistrictOptionsType>
                    sx={sx}
                    id={'district'}
                    fullWidth
                    value={localDistrictValue}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={onLocalChangeDistrict}
                    // @ts-expect-error Wrong type definition from mui?
                    disableClearable={!!requiredDistrict}
                    options={countyDistricts}
                    renderInput={params => (
                        <TextField
                            error={errorDistrict}
                            helperText={helperTextDistrict}
                            required={requiredDistrict && countyDistricts?.length > 0}
                            {...params}
                            label='Bydel'
                        />
                    )}
                />
            )}
        </>
    )
}
