import React, {useRef} from 'react'
import {useParams} from 'react-router'
import {useSearchParams} from 'react-router-dom'
import PurePage from '../../Components/PurePage'
import {Grid, Paper, TextField} from '@mui/material'
import Box from '@mui/system/Box'
import {Button} from '../../Components/Button/MuiButton'
import Alert from '../../Components/Alert'

export default function ForgotPasswordPage() {
    const {email} = useParams()
    const [sp] = useSearchParams()
    const sent = sp.get('sent')
    const formRef = useRef<HTMLFormElement | null>(null)

    return (
        <PurePage
            variant={'xs'}
            breadcrumbs={[{title: 'Logg inn', to: '/'}, {title: 'E-post', to: '/login/email'}, {title: 'Glemt passord'}]}
        >
            <Grid container minHeight={'80vh'} columns={1} direction={'column'} justifyContent={'space-between'}>
                <Grid item>
                    {sent && (
                        <Alert variant={'success'}>
                            Vi har sent en e-post til deg. Vennligst sjekk innboksen din og trykk på lenken for å lage et nytt passord.
                            <br />
                            Vær tålmodig da det kan ta opp til 5 - 10 minutter før den kommer frem.
                        </Alert>
                    )}
                    <form ref={formRef} action='/forgot/password_action/reset' method='post'>
                        <Paper elevation={0}>
                            <Box
                                minHeight={300}
                                overflow={'hidden'}
                                padding={4}
                                display={'flex'}
                                justifyContent={'space-evenly'}
                                flexDirection={'column'}
                            >
                                <TextField
                                    type={'email'}
                                    name={'email'}
                                    required
                                    defaultValue={email || ''}
                                    autoComplete={'email'}
                                    placeholder={'e-postaddressen din'}
                                    label={'E-post'}
                                    helperText={
                                        <>
                                            Innen 5 minutter sender vi en epost med link for å fornye passordet.
                                            <span style={{color: 'orangered'}}>Lenken er gyldig i 24 timer!</span>
                                        </>
                                    }
                                />
                                <Button
                                    onClick={() => formRef.current?.submit()}
                                    style={{marginTop: 15, marginBottom: 15}}
                                    size={'large'}
                                    fullWidth
                                >
                                    Gjenopprett passord
                                </Button>
                            </Box>
                        </Paper>
                    </form>
                    <Grid container>
                        <Button variant={'text'} to='/login/email'>
                            Tilbake
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </PurePage>
    )
}
