import React, {useState} from 'react'
import Modal from '../Components/Modal'
import {toast} from 'react-toastify'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

const validateEmail = email => {
    // Simple email regex pattern
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return pattern.test(email)
}

export default function PartnershipSignupInvoiceModal({signup, signupIndex, onSubmit, onClose}) {
    const [spinnerWhileSaving, setSpinnerWhileSaving] = useState(false)
    const [disableWhileSaving, setDisableWhileSaving] = useState(false)
    const onSave = () => {
        if (!invoiceDetails.name) {
            toast('Kommune er ikke riktig fylt ut', {type: 'error'})
            return
        }
        if (!invoiceDetails.orgNumber || invoiceDetails.orgNumber.toString().length !== 9) {
            toast('Organisasjonsnummer er ikke riktig fylt ut. Må inneholde 9 siffer.', {type: 'error'})
            return
        }
        if (!validateEmail(invoiceDetails.emailForInvoiceReceipt)) {
            toast('E-post til fakturamottak er ikke gyldig', {type: 'error'})
            return
        }
        if (!invoiceDetails.resourceNumber) {
            toast('Ressursnummer er ikke riktig fylt ut', {type: 'error'})
            return
        }
        setSpinnerWhileSaving(true)
        setDisableWhileSaving(true)
        onSubmit(invoiceDetails, signupIndex)
    }
    const [invoiceDetails, setInvoiceDetails] = useState({
        partnershipSignup: signup.partnershipSignup,
        name: '',
        orgNumber: 0,
        emailForInvoiceReceipt: '',
        resourceNumber: '',
        invoiceReference: '',
        invoiceOtherInfo: '',
        internalNotes: '',
        jiraLink: '',
    })

    const onHide = () => {
        setInvoiceDetails({
            partnershipSignup: '',
            name: '',
            orgNumber: 0,
            emailForInvoiceReceipt: '',
            resourceNumber: '',
            invoiceReference: '',
            invoiceOtherInfo: '',
            internalNotes: '',
            jiraLink: '',
        })
        setSpinnerWhileSaving(false)
        setDisableWhileSaving(false)
        onClose()
    }

    return (
        <Modal
            title={'Fakturadetaljer'}
            onClose={onHide}
            onSave={onSave}
            spinnerWhileSaving={spinnerWhileSaving}
            disableWhileSaving={disableWhileSaving}
        >
            <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                    <TextField
                        id='invoiceName'
                        label='Kommune'
                        placeholder='Bergen'
                        value={invoiceDetails.name}
                        required
                        fullWidth
                        onChange={event =>
                            setInvoiceDetails(prev => {
                                return {...prev, name: event.target.value}
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='invoiceOrgNumber'
                        label='Organisasjonsnummer til EHF utsending:'
                        type={'number'}
                        placeholder='123456789'
                        value={invoiceDetails.orgNumber}
                        required={true}
                        fullWidth
                        onChange={event =>
                            setInvoiceDetails(prev => {
                                return {...prev, orgNumber: parseInt(event.target.value)}
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='invoiceEmailReceiver'
                        label='E-post til fakturamottak'
                        placeholder='epost@fakturamottak.no'
                        value={invoiceDetails.emailForInvoiceReceipt}
                        required
                        fullWidth
                        onChange={event =>
                            setInvoiceDetails(prev => {
                                return {...prev, emailForInvoiceReceipt: event.target.value}
                            })
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id='invoiceResourceNumber'
                        label='Ressursnummer / PO nr:'
                        placeholder='Ressursnummer / PO nr'
                        value={invoiceDetails.resourceNumber}
                        required
                        fullWidth
                        onChange={event =>
                            setInvoiceDetails(prev => {
                                return {...prev, resourceNumber: event.target.value}
                            })
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id='invoiceReference'
                        label='Referanse:'
                        placeholder='Referanse'
                        value={invoiceDetails.invoiceReference}
                        fullWidth
                        onChange={event =>
                            setInvoiceDetails(prev => {
                                return {...prev, invoiceReference: event.target.value}
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='invoiceOtherInfo'
                        label='Andre opplysninger:'
                        multiline={true}
                        placeholder='Annet viktig...'
                        value={invoiceDetails.invoiceOtherInfo}
                        fullWidth
                        onChange={event =>
                            setInvoiceDetails(prev => {
                                return {...prev, invoiceOtherInfo: event.target.value}
                            })
                        }
                    />
                    <hr />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='invoiceNotes'
                        label='SKILs interne notater'
                        multiline={true}
                        placeholder='SKILs interne notater...'
                        value={invoiceDetails.internalNotes}
                        fullWidth
                        onChange={event =>
                            setInvoiceDetails(prev => {
                                return {...prev, internalNotes: event.target.value}
                            })
                        }
                    />
                </Grid>
            </Grid>
        </Modal>
    )
}
