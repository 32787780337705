import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import { useGridRegisterPipeProcessor } from '@mui/x-data-grid/internals';
import { GRID_DETAIL_PANEL_TOGGLE_FIELD, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from './gridDetailPanelToggleColDef';
import { gridDetailPanelExpandedRowIdsSelector } from './gridDetailPanelSelector';
export const useGridDetailPanelPreProcessors = (apiRef, props) => {
  const addToggleColumn = React.useCallback(columnsState => {
    if (props.getDetailPanelContent == null) {
      // Remove the toggle column, when it exists
      if (columnsState.lookup[GRID_DETAIL_PANEL_TOGGLE_FIELD]) {
        delete columnsState.lookup[GRID_DETAIL_PANEL_TOGGLE_FIELD];
        columnsState.all = columnsState.all.filter(field => field !== GRID_DETAIL_PANEL_TOGGLE_FIELD);
      }

      return columnsState;
    } // Don't add the toggle column if there's already one
    // The user might have manually added it to have it in a custom position


    if (columnsState.lookup[GRID_DETAIL_PANEL_TOGGLE_FIELD]) {
      return columnsState;
    } // Othewise, add the toggle column at the beginning


    columnsState.all = [GRID_DETAIL_PANEL_TOGGLE_FIELD, ...columnsState.all];
    columnsState.lookup[GRID_DETAIL_PANEL_TOGGLE_FIELD] = _extends({}, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, {
      headerName: apiRef.current.getLocaleText('detailPanelToggle')
    });
    return columnsState;
  }, [apiRef, props.getDetailPanelContent]);
  const addExpandedClassToRow = React.useCallback((classes, id) => {
    if (props.getDetailPanelContent == null) {
      return classes;
    }

    const expandedRowIds = gridDetailPanelExpandedRowIdsSelector(apiRef.current.state);

    if (!expandedRowIds.includes(id)) {
      return classes;
    }

    return [...classes, 'MuiDataGrid-row--detailPanelExpanded'];
  }, [apiRef, props.getDetailPanelContent]);
  useGridRegisterPipeProcessor(apiRef, 'hydrateColumns', addToggleColumn);
  useGridRegisterPipeProcessor(apiRef, 'rowClassName', addExpandedClassToRow);
};