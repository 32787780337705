'use strict';

exports.__esModule = true;
exports.createDynamicMiddlewares = exports.resetMiddlewares = exports.removeMiddleware = exports.addMiddleware = undefined;

var _redux = require('redux');

var createDynamicMiddlewares = function createDynamicMiddlewares() {
  var allDynamicMiddlewares = [];

  var enhancer = function enhancer(store) {
    return function (next) {
      return function (action) {
        var chain = allDynamicMiddlewares.map(function (middleware) {
          return middleware(store);
        });

        return _redux.compose.apply(undefined, chain)(next)(action);
      };
    };
  };

  var addMiddleware = function addMiddleware() {
    for (var _len = arguments.length, middlewares = Array(_len), _key = 0; _key < _len; _key++) {
      middlewares[_key] = arguments[_key];
    }

    allDynamicMiddlewares = [].concat(allDynamicMiddlewares, middlewares);
  };

  var removeMiddleware = function removeMiddleware(middleware) {
    var index = allDynamicMiddlewares.findIndex(function (d) {
      return d === middleware;
    });

    if (index === -1) {
      // eslint-disable-next-line no-console
      console.error('Middleware does not exist!', middleware);

      return;
    }

    allDynamicMiddlewares = allDynamicMiddlewares.filter(function (_, mdwIndex) {
      return mdwIndex !== index;
    });
  };

  var resetMiddlewares = function resetMiddlewares() {
    allDynamicMiddlewares = [];
  };

  return {
    enhancer: enhancer,
    addMiddleware: addMiddleware,
    removeMiddleware: removeMiddleware,
    resetMiddlewares: resetMiddlewares
  };
};

var dynamicMiddlewaresInstance = createDynamicMiddlewares();

exports.default = dynamicMiddlewaresInstance.enhancer;
var addMiddleware = dynamicMiddlewaresInstance.addMiddleware,
    removeMiddleware = dynamicMiddlewaresInstance.removeMiddleware,
    resetMiddlewares = dynamicMiddlewaresInstance.resetMiddlewares;
exports.addMiddleware = addMiddleware;
exports.removeMiddleware = removeMiddleware;
exports.resetMiddlewares = resetMiddlewares;
exports.createDynamicMiddlewares = createDynamicMiddlewares;