import React from 'react'
import {styled} from '@mui/material'

const AbsoluteDiv = styled('div')(({theme}) => ({
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2em',
    color: theme.palette.primary.main,
}))

type Props = {
    msg?: string
    children?: React.ReactNode
    size?: string
    color?: string
    absolute?: boolean
}

const LoadingComponent = ({msg = undefined, children = undefined, size = 'h4', color = 'default', absolute = true}: Props) => {
    return React.createElement(
        size,
        {className: 'text-center loading-component', title: msg},
        <>
            {absolute && (
                <AbsoluteDiv>
                    <i className='fa fa-spinner fa-spin fa' style={{color: color, marginRight: '0.3em'}} />
                    {children && <>{children}</>}
                    <br />
                    <small>{msg}</small>
                </AbsoluteDiv>
            )}
            {!absolute && (
                <>
                    <i className='fa fa-spinner fa-spin fa-2x' style={{color: color, marginRight: '0.3em'}} />
                    {children && (
                        <>
                            <br />
                            <br />
                            {children}
                        </>
                    )}
                    <br />
                    <small>{msg}</small>
                </>
            )}
        </>
    )
}
export default LoadingComponent
