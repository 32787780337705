import * as React from 'react'
import Button from '../../Components/Button/Button'
import Collapse from '../../Components/Collapse'
import {FindUserModalDTO} from '../../Components/FindUserModal'
import FormGroup from '../../Components/FormGroup'
import styled from 'styled-components'
import {SetState} from '../../Utilities/TypeHelpers'
import CheckboxControl from '../../Components/Fields/CheckboxControl'
import {components} from '../../Generated/eportal'

const StyledCheckbox = styled(CheckboxControl)`
    display: inline-block;

    input {
        position: inherit !important;
        margin-left: inherit !important;
        margin-right: 7px;
    }
`

const StyledFormGroup = styled(FormGroup)`
    display: flex;
    margin-left: 0.5em;
    margin-bottom: 0.5em;
    justify-content: space-between;
    label {
        font-weight: normal;
    }

    @media only screen and (max-width: 600px) {
        display: block;

        label {
            display: block;
            width: 100%;
        }

        input[type='text'] {
            display: block;
            width: 100%;
        }
    }
`

type OfficeType =
    components['schemas']['OfficeDto.jsonld-Stats.basic_Stats.offices_Office.basic_Office.users_User.basic_User.censoredEmail_User.email-validation_User.userRole_UserRole.basic_Stats.partnership_Partnership.basic_Partnership.details_Partnership.seminars_Partnership.members_Member.basic']
type UserType =
    components['schemas']['User.jsonld-Stats.basic_Stats.offices_Office.basic_Office.users_User.basic_User.censoredEmail_User.email-validation_User.userRole_UserRole.basic_Stats.partnership_Partnership.basic_Partnership.details_Partnership.seminars_Partnership.members_Member.basic']

export type ParticipantsType = {users: number[]; extraUsers: FindUserModalDTO[]; init: boolean}
type ParticipantsProps = {
    participants: ParticipantsType
    setParticipants: SetState<ParticipantsType>
    toggleUser: (user: UserType, checked: boolean) => void
    toggleOffice: (office: OfficeType, checked: boolean) => void
    selected: number
    expanded: number | undefined
    setExpanded: SetState<number | undefined>
    o: OfficeType
}

export default function OfficeComponent({participants, o, toggleUser, toggleOffice, selected, expanded, setExpanded}: ParticipantsProps) {
    return (
        <div key={o.office!.id}>
            <div style={{marginBottom: '1em'}}>
                <Button
                    onClick={() => setExpanded(id => (id === o.office?.id ? undefined : o.office?.id))}
                    inline
                    variant={'link'}
                    style={{fontSize: 'initial'}}
                >
                    {o.office!.name} <i className={'fa fa-caret-down'} />
                </Button>
                <small>
                    {' '}
                    {selected} av {o.users.length} valgt{' '}
                </small>
                <span className={'pull-right'}>
                    <Button onClick={() => toggleOffice(o, true)} inline variant={'link'}>
                        Velg alle
                    </Button>
                    <Button onClick={() => toggleOffice(o, false)} inline variant={'link'}>
                        Velg ingen
                    </Button>
                </span>
            </div>
            <Collapse expanded={o.office!.id === expanded}>
                {o.users.map(d => {
                    const checked = participants.users.includes(d.user!.id)
                    return (
                        <StyledFormGroup key={d.user!.id}>
                            <StyledCheckbox
                                id={d.user!.id.toString()}
                                checked={checked}
                                disabled={false}
                                onChange={e => toggleUser(d.user!, e.target.checked)}
                            >
                                {d.user!.name}
                                {!d.user!.validEmail && (
                                    <i
                                        title='Ugyldig e-postadresse. Brukeren vil mest sannsynlig ikke motta e-poster og invitasjoner.'
                                        className='fa fa-exclamation-triangle'
                                        style={{marginLeft: '1em'}}
                                        aria-hidden='false'
                                    >
                                        Ugyldig e-postadresse
                                    </i>
                                )}
                            </StyledCheckbox>

                            <small> (Eksisterende SKIL bruker) {d.user!.sensoredEmail}</small>
                        </StyledFormGroup>
                    )
                })}
                <span className={''}>
                    <Button onClick={() => toggleOffice(o, true)} inline variant={'link'}>
                        Velg alle
                    </Button>
                    <Button onClick={() => toggleOffice(o, false)} inline variant={'link'}>
                        Velg ingen
                    </Button>
                </span>
            </Collapse>
            <hr />
        </div>
    )
}
