import * as React from 'react'
import DataGrid from '../../Components/DataGrid/DataGrid'
import {GridColDef, GridSelectionModel} from '@mui/x-data-grid-premium'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import SpecialActions from '../../Components/DataGrid/SpecialActions'
import {SpecialActionProps} from '../../Components/DataGrid/SpecialAction'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import {useSkilMutation, useSkilQuery} from '../../Utilities/QueryClient'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import {useNavigate} from 'react-router-dom'
import {ROUTES} from '../constants/routes'
import CircularProgress from '@mui/material/CircularProgress'
import FindUserModal, {FindUserModalDTO} from '../../Components/FindUserModal'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'

const columnVisibilityModel = {
    id: false,
}

const columns: GridColDef[] = [
    columnDef({field: 'id', headerName: 'System-ID', type: 'number'}),
    columnDef({field: 'name', headerName: 'Navn', type: 'string'}),
    columnDef({field: 'email', headerName: 'Epost', type: 'string', flex: 2}),
]

const formattedColumns: GridColDef[] = columns.map(column => ({headerAlign: 'left', ...column}))

export const PraksisnettUsers = () => {
    const navigate = useNavigate()
    const [showAddUser, setShowAddUser] = React.useState(false)
    const [removingUsers, setRemovingUsers] = React.useState(false)
    const [selectedUsers, setSelectedUsers] = React.useState<GridSelectionModel>([])
    const praksisnettUsersResponse = useSkilQuery<'getPraksisnettUsers'>('/api/users/praksisnett_users', {pagination: false})
    const addUserMutation = useSkilMutation<'addUser'>('POST', `/api/users`, ['/api/users/praksisnett_users'])
    const addPraksisnettRoleMutation = useSkilMutation<'addPraksisnettRole'>('POST', `/api/users/add_praksisnett_role`, [
        `/api/users/praksisnett_users`,
    ])
    const removePraksisnettRoleMutation = useSkilMutation<'removePraksisnettRole'>('POST', `/api/users/remove_praksisnett_role`, [
        `/api/users/praksisnett_users`,
    ])
    const praksisnettUsers = praksisnettUsersResponse.data?.['hydra:member'] ?? []
    const currentSelection = praksisnettUsers.filter(i => selectedUsers.includes(Number(i.id)))

    const onAddUser = async (user: FindUserModalDTO) => {
        // if the user is already a skil user then just add the praksisnett role
        if (user.type === 'skil') {
            await addPraksisnettRoleMutation.mutateAsync({user: `api/users/${user.id}`})
            praksisnettUsersResponse.refetch()
        } else {
            const response = await addUserMutation.mutateAsync(user)
            // after the user is created then add the praksisnett role
            if (response['@id']) {
                await addPraksisnettRoleMutation.mutateAsync({user: response['@id']})
                praksisnettUsersResponse.refetch()
            } else {
                handleErrorWithToast('Kunne ikke legge til praksisnett bruker')
            }
        }
        setShowAddUser(false)
    }

    const onRemoveUsers = async () => {
        setRemovingUsers(true)
        if (currentSelection.length > 0) {
            const usersToRemove: string[] = currentSelection.map(selected => {
                return selected['@id'] ?? ''
            })
            if (usersToRemove.length > 0) {
                await removePraksisnettRoleMutation
                    // @ts-expect-error
                    .mutateAsync({users: usersToRemove})
                    .then(() => {
                        setSelectedUsers([])
                        praksisnettUsersResponse.refetch()
                    })
                    .finally(() => {
                        setRemovingUsers(false)
                    })
            }
        }
    }

    const userActions: SpecialActionProps[] = [
        {
            label: 'Legg til bruker',
            action: () => setShowAddUser(true),
            icon: {
                position: 'start',
                icon: <AddIcon />,
            },
        },
        {
            label: removingUsers ? 'arbeider...' : 'Fjern bruker(e)',
            action: () => onRemoveUsers(),
            disabled: selectedUsers.length === 0,
            icon: {
                position: 'start',
                icon: removingUsers ? <i className={'fa fa-spin fa-spinner'} /> : <EditIcon />,
            },
        },
    ]

    return (
        <>
            {praksisnettUsersResponse.isLoading && !praksisnettUsers ? (
                <CircularProgress />
            ) : (
                <DataGridWrapper rows={praksisnettUsers.length} addHeight={8.5}>
                    <DataGrid
                        id={'praksisnett_users'}
                        checkboxSelection
                        disableSelectionOnClick
                        loading={praksisnettUsersResponse.isFetching}
                        rows={praksisnettUsers}
                        columns={formattedColumns}
                        initModel={{columnVisibilityModel}}
                        onSelectionModelChange={newSelectedUsers => {
                            setSelectedUsers(newSelectedUsers)
                        }}
                        selectionModel={selectedUsers}
                        toolbarButtons={<SpecialActions actions={userActions} />}
                    />
                </DataGridWrapper>
            )}
            {showAddUser && <FindUserModal onSave={onAddUser} onClose={() => setShowAddUser(false)} />}
        </>
    )
}
