import * as React from 'react'
import {useParams, useNavigate} from 'react-router'
import Panel from '../Components/Panel'
import Button from '../Components/Button/Button'
import FindUserModal from '../Components/FindUserModal'
import {Page} from '../Components/Page'
import {toast} from 'react-toastify'
import {jsonFetch} from '../Components/jsonFetch'
import LoadingComponent from '../Components/LoadingComponent'
import {handleErrorWithToast} from '../Utilities/errorHandlers'
import {SkilQueryResponseType, useQueryInvalidate, useSkilMutation, useSkilQuery} from '../Utilities/QueryClient'
import useUser from '../Utilities/useUser'
import {BreadcrumbsList} from '../Components/Breadcrumbs'
import {useState} from 'react'
import DateTimeField from '../Components/Fields/DateTimeField'
import TextField from '../Components/Fields/TextField'
import TextareaField from '../Components/Fields/TextareaField'
import ConfirmMeetingParticipation from '../Components/ConfirmMeetingParticipation'
import moment from 'moment'
import CheckboxField from '../Components/Fields/CheckboxField'
import RemoveUserFromSeminarDialog from '../Skil/Components/RemoveUserFromSeminarDialog'
import he from 'he'

export type CustomUserType = {
    status: string
    name: string
    officeName: string
    id: number
    '@id': string
    roles: string[]
    userRole: string
    office?: string
    county: string
    region: string
    acceptedAt?: string
    userCourseId?: string
}

export default function Meeting() {
    const {partnershipId, meetingId, groupId} = useParams()
    const {voters, name} = useUser()
    const navigate = useNavigate()
    const meetingIri = `/api/meetings/${meetingId}`
    const saveChangesToMeeting = useSkilMutation<'saveChangesToMeeting'>('post', `/api/meetings/${meetingId}/save_changes`)
    const {data: partnership} = useSkilQuery<'getPartnershipItem'>(`/api/partnerships/${partnershipId}`, {}, {enabled: !!partnershipId})
    const {data: group} = useSkilQuery<'getGroupItem'>(`/api/groups/${groupId}`, {}, {enabled: !!groupId})
    const {data: meeting, isSuccess: isMeetingLoaded, refetch: refetchMeeting} = useSkilQuery<'getMeetingItem'>(meetingIri)
    const {
        data: availableUsers,
        isSuccess: availableUsersLoaded,
        refetch: availableUsersRefresh,
    } = useSkilQuery(`${meetingIri}/available_users`) as {
        data: CustomUserType[]
        isSuccess: boolean
        refetch: () => void
    }
    const now = new Date()
    // try to get the approved meeting hours from the task, if not use 3 hours as default
    const approvedMeetingHours = meeting?.task?.approvedMeetingHours ?? 3
    const approvedHoursAfterMeetingStart = moment(meeting?.time).add(approvedMeetingHours, 'hours')
    const isAfterMeeting = meeting?.time && approvedHoursAfterMeetingStart.toDate() < now
    let usersIri = meetingIri + (isAfterMeeting ? `/attended_users` : `/available_users`)
    const {data: usersData, isSuccess: usersLoaded, refetch: usersDataRefresh} = useSkilQuery(usersIri, {}, {enabled: !!meeting})
    const invalidate = useQueryInvalidate()
    const users: CustomUserType[] = usersData?.['hydra:member'] ?? []
    const missingUsers = (availableUsers?.['hydra:member'] ?? []).filter(a => !users.some(b => a.id === b.id))
    const isAdminOrCounselor = voters.isAdminOrCounselor(meeting?.course!['@id'])
    const isCourseAdmin = voters.isCourseAdmin(meeting?.course!['@id'])
    const isGroupMember = voters.isGroupMember(meeting?.group)
    const isSkilUser = voters.isSkilUser()
    const canDeleteMeeting = (meeting?.group && (isAdminOrCounselor || isGroupMember || partnershipId)) || isSkilUser
    const [showAddMember, setShowAddMember] = React.useState(false)
    const [showConfirmMeetingParticipation, setShowConfirmMeetingParticipation] = React.useState(false)
    const [time, setTime] = useState<Date>()
    const [location, setLocation] = useState<string>()
    const [summary, setSummary] = useState<string>()
    const selectedTime = time ?? meeting?.time ?? ''
    const selectedLocation = location ?? meeting?.location ?? ''
    const selectedSummary = summary ?? meeting?.summary ?? ''
    const [recentlyAddedIri, setRecentlyAddedIri] = React.useState<{iri: string}[]>([])
    const [sendNotificationOnMeetingChange, setSendNotificationOnMeetingChange] = React.useState<boolean>(true)
    const hasChangedMeetingTime = time && time.toISOString() !== meeting?.time
    const hasChangedMeetingLocation = location && location !== meeting?.location
    const showRemoveUser = partnershipId && !meeting?.confirmed && isSkilUser
    const [showConfirmRemoveUser, setShowConfirmRemoveUser] = useState(true)
    const [userToRemove, setUserToRemove] = useState<CustomUserType>()

    const onSave = async () => {
        try {
            await saveChangesToMeeting.mutateAsync({
                meeting: meetingIri,
                time: time ? time.toISOString() : meeting!.time,
                location: selectedLocation,
                summary: selectedSummary,
                notify: sendNotificationOnMeetingChange,
            })
            await invalidate([meetingIri, group ? `${group['@id']}/meetings` : null])
            toast('Endringene lagret', {type: 'success'})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    const onConfirmMeeting = async () => {
        try {
            await jsonFetch(meetingIri + '/confirm_meeting', {
                json: {
                    meeting: meetingIri,
                },
                method: 'PATCH',
            })
            await refetchMeeting()
            await invalidate([meetingIri, group ? `${group['@id']}/meetings` : null])
            toast('Deltakerlisten er godkjent', {type: 'success'})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    const onUnConfirmMeeting = async () => {
        try {
            await jsonFetch(meetingIri + '/unconfirm_meeting', {
                json: {
                    meeting: meetingIri,
                },
                method: 'PATCH',
            })
            await refetchMeeting()
            await invalidate([meetingIri, group ? `${group['@id']}/meetings` : null])
            toast(
                'Deltakerlisten er ikke lengre godkjent og kan redigeres. Dette vil forhinde brukere fra å laste ned kursbevis. Husk å godkjenne når du er ferdig å redigere.',
                {type: 'success'}
            )
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    const onAddMember = async addUser => {
        try {
            // /add_user is untyped
            const res: any = await jsonFetch(meetingIri + '/add_user', {
                body: JSON.stringify({
                    user: addUser,
                    meeting: meetingIri,
                }),
            })

            await invalidate([meetingIri, `${meetingIri}/available_users`, `${meetingIri}/attended_users`, `/api/groups/${group?.id}`])
            toast(res.message, {type: 'success'})
            setRecentlyAddedIri(prev => [...prev, {iri: res.iri}])
        } catch (err) {
            handleErrorWithToast(err)
        } finally {
            setShowAddMember(false)
        }
    }

    const onRemoveMeeting = async () => {
        try {
            await jsonFetch(meetingIri, {method: 'DELETE'})
            await invalidate([meetingIri, `${meetingIri}/available_users`, `${meetingIri}/attended_users`])
            toast('Møtet er slettet', {type: 'success'})
            navigate(-1)
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    const onRemoveUserMeeting = async (iri, name) => {
        try {
            await jsonFetch(iri + '/remove_usermeeting', {
                json: {
                    meeting: meeting!['@id'],
                },
            })
            await usersDataRefresh()
            await availableUsersRefresh()
            await invalidate([meetingIri, `${meetingIri}/available_users`, `${meetingIri}/attended_users`])
            toast(name + ' ble fjernet fra møte', {type: 'success'})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    const onRemoveUser = async (iri, name) => {
        try {
            await jsonFetch(iri + '/remove_usercourse', {
                json: {
                    meeting: meeting!['@id'],
                },
            })
            await usersDataRefresh()
            await availableUsersRefresh()
            toast(name + ' ble fjernet fra seminaret', {type: 'success'})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    const onAddUserMeeting = async (iri, name) => {
        try {
            await jsonFetch(iri + '/add_usermeeting', {
                json: {
                    meeting: meeting!['@id'],
                },
            })
            await invalidate([meetingIri, `${meetingIri}/available_users`, `${meetingIri}/attended_users`])
            toast(name + ' ble lagt til møtet', {type: 'success'})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    React.useEffect(() => {
        const loop = setInterval(() => {
            setRecentlyAddedIri([])
        }, 15000)

        if (recentlyAddedIri.length === 0) {
            clearInterval(loop)
        }
    }, [recentlyAddedIri])
    let breadcrumbs = createBreadcrumb({group, partnership, meeting})

    return (
        <Page breadcrumbs={breadcrumbs} variant={'full'}>
            {!isMeetingLoaded && <LoadingComponent />}
            {meeting?.['@id'] && (
                <Panel variant={'primary'} title={meeting.task?.title} hidden={!isMeetingLoaded}>
                    <Panel.Body>
                        <h2>
                            {meeting.task?.title} for {meeting.course?.title}
                        </h2>
                    </Panel.Body>
                    <Panel.Body>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div style={{marginBottom: '1rem'}}>
                                    <DateTimeField
                                        label={'Tidspunkt'}
                                        value={selectedTime}
                                        onChange={time => setTime(time)}
                                        disabled={meeting?.confirmed}
                                        id={'time'}
                                    />
                                </div>
                                <div style={{marginBottom: '1rem'}}>
                                    <TextField
                                        label={'Sted'}
                                        type={'text'}
                                        value={selectedLocation}
                                        onChange={location => setLocation(location)}
                                        disabled={meeting?.confirmed}
                                        id={'sted'}
                                    />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div style={{marginBottom: '1rem'}}>
                                    <TextField
                                        type={'text'}
                                        label={'Tema'}
                                        value={meeting.task?.title}
                                        onChange={() => {}}
                                        disabled={meeting?.confirmed}
                                        id={'title'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <TextareaField
                                    label={'Beskrivelse'}
                                    value={selectedSummary}
                                    disabled={meeting?.confirmed}
                                    onChange={summary => setSummary(summary)}
                                    id={'summary'}
                                />
                            </div>
                        </div>
                        {(hasChangedMeetingLocation || hasChangedMeetingTime) && (
                            <div className='row'>
                                <div className='col-md-12'>
                                    <p>Send varsel til deltakere om endring av møtetidspunkt / sted?</p>
                                    <CheckboxField
                                        id='notifyOnMeetingChange'
                                        label='Send varsel'
                                        value={sendNotificationOnMeetingChange}
                                        defaultChecked={true}
                                        onChange={event => {
                                            setSendNotificationOnMeetingChange(event)
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        <div className='row'>
                            <div className='col-md-12'>
                                <Button
                                    onClick={onSave}
                                    variant={'primary'}
                                    disabled={meeting?.confirmed}
                                    title={meeting?.confirmed ? `Kan ikke lagre endringer, deltakerlisten er godkjent` : ``}>
                                    Lagre
                                </Button>
                                <Button
                                    hidden={!canDeleteMeeting}
                                    variant={'warning'}
                                    onClick={onRemoveMeeting}
                                    disabled={meeting?.confirmed}
                                    title={meeting?.confirmed ? `Kan ikke slette møtet, deltakerlisten er godkjent` : ``}
                                    confirm={'Er du sikker på at du vil slette møtet (Dette kan ikke angres!)?'}>
                                    Slett
                                </Button>
                            </div>
                        </div>
                    </Panel.Body>
                </Panel>
            )}
            {availableUsersLoaded && usersLoaded && (
                <Panel title={'Deltakere'} variant={'default'} hidden={!isMeetingLoaded}>
                    <div className={'table-responsive'}>
                        <table className={'table'}>
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Navn</th>
                                    <th>Yrke</th>
                                    <th>Kontor</th>
                                    <th>Kommune</th>
                                    <th>Fylke</th>
                                    <th />
                                    {isAfterMeeting && !meeting?.confirmed && <th>Deltakelse</th>}
                                    {showRemoveUser && <th>Påmelding</th>}
                                </tr>
                                <tr>
                                    <th>Tilstede</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th />
                                    {isAfterMeeting && !meeting?.confirmed && <th />}
                                    {showRemoveUser && <th />}
                                </tr>
                            </thead>
                            <tbody>
                                {users.length < 1 && (
                                    <tr>
                                        <td>Det er ingen deltakere tilstede</td>
                                    </tr>
                                )}
                                {users.map(u => {
                                    let color = recentlyAddedIri.find(ra => ra.iri === u['@id']) !== undefined ? '#d2d6de' : 'none'
                                    return (
                                        <tr key={u.id} style={{background: color}}>
                                            <td>{u.status}</td>
                                            <td>
                                                {(isCourseAdmin && u.acceptedAt) || isSkilUser ? (
                                                    <a href={`/dashboard/users/${u.id}`}>
                                                        <span style={{textDecoration: 'underline'}}>{u.name}</span>
                                                    </a>
                                                ) : (
                                                    u.name
                                                )}
                                            </td>
                                            <td>{u.userRole ?? `Ukjent`}</td>
                                            <td>{u.officeName ?? 'Ukjent'}</td>
                                            <td>{u.county ?? 'Ukjent'}</td>
                                            <td>{u.region ?? 'Ukjent'}</td>
                                            <td>
                                                {u.roles
                                                    .filter(r => r !== 'Deltaker')
                                                    .map(r => (
                                                        <em key={r} className='label label-primary pull-right'>
                                                            {r}
                                                        </em>
                                                    ))}
                                            </td>
                                            {isAfterMeeting && !meeting?.confirmed && (
                                                <td>
                                                    <Button
                                                        inline
                                                        variant={'link'}
                                                        confirm='Bekreft fjerning'
                                                        onClick={() => onRemoveUserMeeting(u['@id'], u.name)}>
                                                        Fjern fra møtet
                                                    </Button>
                                                </td>
                                            )}
                                            {showRemoveUser && (
                                                <td>
                                                    <Button
                                                        inline
                                                        variant={'link'}
                                                        onClick={() => {
                                                            setUserToRemove(u)
                                                            setShowConfirmRemoveUser(true)
                                                        }}>
                                                        Slett fra seminaret
                                                    </Button>
                                                </td>
                                            )}
                                        </tr>
                                    )
                                })}
                            </tbody>
                            <thead>
                                {missingUsers.length > 0 && (
                                    <tr>
                                        <th>Fraværende</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th />
                                        {isAfterMeeting && !meeting?.confirmed && <th />}
                                        {partnershipId && <th />}
                                    </tr>
                                )}
                                {missingUsers.length < 1 && (
                                    <tr>
                                        <th>Fraværende</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th />
                                        <th />
                                        <th />
                                    </tr>
                                )}
                            </thead>
                            <tbody>
                                {missingUsers.length < 1 && (
                                    <tr>
                                        <td>Det er ingen fraværende deltakere</td>
                                    </tr>
                                )}
                                {missingUsers.map(u => {
                                    let color = recentlyAddedIri.find(ra => ra.iri === u['@id']) !== undefined ? '#d2d6de' : 'lightgrey'
                                    {
                                        return (
                                            <tr key={u.id} style={{background: color}}>
                                                <td>{u.status}</td>
                                                <td>
                                                    {(isCourseAdmin && u.acceptedAt) || isSkilUser ? (
                                                        <a href={`/dashboard/users/${u.id}`}>
                                                            <span style={{textDecoration: 'underline'}}>{u.name}</span>
                                                        </a>
                                                    ) : (
                                                        u.name
                                                    )}
                                                </td>
                                                <td>{u.userRole ?? `Ukjent`}</td>
                                                <td>{u.officeName ?? 'Ukjent'}</td>
                                                <td>{u.county ?? 'Ukjent'}</td>
                                                <td>{u.region ?? 'Ukjent'}</td>
                                                <td>
                                                    {u.roles
                                                        .filter(r => r !== 'Deltaker')
                                                        .map(r => (
                                                            <em key={r} className='label label-primary pull-right'>
                                                                {r}
                                                            </em>
                                                        ))}
                                                </td>
                                                {isAfterMeeting && !meeting?.confirmed && (
                                                    <td>
                                                        {u.status !== 'Invitert' && (
                                                            <Button
                                                                inline
                                                                variant={'link'}
                                                                confirm='Bekreft legg til'
                                                                onClick={() => onAddUserMeeting(u['@id'], u.name)}>
                                                                Legg til i møtet
                                                            </Button>
                                                        )}
                                                        {u.status === 'Invitert' && <small style={{padding: '0 3px'}}>Ikke påmeldt</small>}
                                                    </td>
                                                )}
                                                {showRemoveUser && (
                                                    <td>
                                                        <Button
                                                            inline
                                                            variant={'link'}
                                                            onClick={() => {
                                                                setUserToRemove(u)
                                                                setShowConfirmRemoveUser(true)
                                                            }}>
                                                            Slett fra seminaret
                                                        </Button>
                                                    </td>
                                                )}
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        </table>
                        <Panel.Footer>
                            <Button
                                disabled={meeting?.confirmed}
                                title={meeting?.confirmed ? `Deltakerlisten er godkjent og låst` : ``}
                                onClick={() => setShowAddMember(true)}>
                                Legg til deltaker
                            </Button>
                            {partnershipId && isMeetingLoaded && (isCourseAdmin || isSkilUser) && (
                                <Button
                                    variant={`primary`}
                                    disabled={meeting?.confirmed || !isAfterMeeting}
                                    title={
                                        !isAfterMeeting
                                            ? ` NB! Det er først mulig å godkjenne deltakerlisten ${approvedMeetingHours} timer etter møtet startet`
                                            : meeting?.confirmed
                                            ? `Deltakerlisten er godkjent og låst`
                                            : ``
                                    }
                                    onClick={() => setShowConfirmMeetingParticipation(true)}>
                                    Godkjenn deltakerlisten
                                </Button>
                            )}
                            {!partnershipId && isMeetingLoaded && (
                                <Button
                                    variant={`primary`}
                                    disabled={meeting?.confirmed || !isAfterMeeting}
                                    title={
                                        !isAfterMeeting
                                            ? ` NB! Det er først mulig å godkjenne deltakerlisten ${approvedMeetingHours} timer etter møtet startet`
                                            : meeting?.confirmed
                                            ? `Deltakerlisten er godkjent og låst`
                                            : ``
                                    }
                                    onClick={() => setShowConfirmMeetingParticipation(true)}>
                                    Godkjenn deltakerlisten
                                </Button>
                            )}
                            {meeting?.confirmed && isSkilUser && (
                                <Button variant={`primary`} onClick={() => onUnConfirmMeeting()}>
                                    Opphev godkjenning for å redigere
                                </Button>
                            )}
                            {!isAfterMeeting
                                ? ` NB! Det er først mulig å godkjenne deltakerlisten ${approvedMeetingHours} timer etter møtet startet`
                                : ''}
                            {showConfirmMeetingParticipation && (
                                <ConfirmMeetingParticipation
                                    onClose={() => setShowConfirmMeetingParticipation(false)}
                                    onSave={async () => {
                                        await onConfirmMeeting()
                                        setShowConfirmMeetingParticipation(false)
                                    }}
                                    users={users}
                                    missingUsers={missingUsers}
                                    name={name}
                                />
                            )}
                            {showAddMember && (
                                <FindUserModal
                                    onClose={() => setShowAddMember(false)}
                                    onSave={onAddMember}
                                    signupAllowed={meeting?.course?.signupAllowed}
                                    shortTitle={meeting?.course?.shortTitle}
                                    isCourseAdmin={isCourseAdmin}
                                    isSkilUser={isSkilUser}
                                    size={'sm'}
                                />
                            )}
                        </Panel.Footer>
                    </div>
                </Panel>
            )}
            {userToRemove?.name && (
                <RemoveUserFromSeminarDialog
                    userName={userToRemove?.name}
                    shortTitle={meeting?.course?.shortTitle}
                    userCourseId={userToRemove?.userCourseId}
                    isOpen={showConfirmRemoveUser}
                    handleClose={() => setShowConfirmRemoveUser(false)}
                    handleSubmit={() => onRemoveUser(userToRemove?.['@id'], userToRemove?.name)}
                />
            )}
        </Page>
    )
}

type BreadcrumbProps = {
    group?: SkilQueryResponseType<'getGroupItem'>
    partnership?: SkilQueryResponseType<'getPartnershipItem'>
    meeting?: SkilQueryResponseType<'getMeetingItem'>
}
function createBreadcrumb({group, partnership, meeting}: BreadcrumbProps): BreadcrumbsList {
    if (partnership?.id && group?.id) {
        return [
            {title: 'Kommunedashboard', to: '/dashboard/kommuner/' + partnership.id},
            {title: 'Grupper', to: `/dashboard/kommuner/${partnership.id}/groups`},
            {title: he.decode(group.title), to: `/dashboard/kommuner/${partnership.id}/groups/${group.id}`},
            {title: meeting?.name ?? ''},
        ]
    }
    if (partnership?.id) {
        return [{title: 'Kommunedashboard', to: '/dashboard/kommuner/' + partnership.id}, {title: meeting?.name ?? ''}]
    }

    if (group?.id) {
        return [
            {title: 'Dashboard', href: '/dashboard'},
            {title: he.decode(group.title), to: `/dashboard/groups/${group.id}`},
            {title: meeting?.name ?? ''},
        ]
    }

    return [{title: 'Dashboard', href: '/dashboard'}, {title: 'Møter'}, {title: meeting?.name ?? ''}]
}
